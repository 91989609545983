<template>
    <div id="createPregnancyEpisodeOfCare" class="overlay overlay--patient" v-if="isClinician">
        <bar :title="$t(`createPregnancyEpisodeOfCare.title`)" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <!--<div class="col-xs-12">
                <h2 class="full-width center-text pb-small"><b>{{$t('createPregnancyEpisodeOfCare.title')}}</b></h2>
                <h5 class="full-width center-text pb-small">{{$t('addRelatedPerson.subTitle')}}</h5>
                </div>-->
                <div class="content">
                    <div class="col-xs-12">
                        <form>
                            <date-time v-model="eoc.termDate"
                                       name="termDate"
                                       type="date"
                                       :required="true"
                                       :label="$t('createPregnancyEpisodeOfCare.termDateLabel')"
                                       :errorText="$t('createPregnancyEpisodeOfCare.termDateErrorText')"
                                       :helper="$t('createPregnancyEpisodeOfCare.termDateHelperText')"
                                       @updated="stateChanged"
                                       data-test="termDate"
                                       class="online-only" />
                        </form>
                        <div class="term-date-alert row" :class="{show:termDateChanged}">
                            <div class="col-xs-12">
                                <button type="button" @click="updateAntiDDate" class="btn btn__text btn__text--green-border">{{$t('createPregnancyEpisodeOfCare.updateRecAntiDInjectionDate')}}</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="content">
                    <div class="col-xs-12">
                        <div class="row">
                            <div class="col-xs-6 col-sm-3">
                                <measurement-link-button :measurement-definition-id="bloodTypeKey"
                                                         measured-icon-name="bloodtype"
                                                         :measured-label="$t('createPregnancyEpisodeOfCare.bloodTypeLabel')"
                                                         non-measured-icon-name="measurements"
                                                         :non-measured-label="$t('createPregnancyEpisodeOfCare.registerNewBloodTypeLink')"
                                                         create-measurement-route-name="eoccreate create measurement"
                                                         return-to-name="createpregnancyepisodeofcare"
                                                         :disable-link-when-has-measurement="true">
                                    <template v-slot="{comps}">
                                        <b>
                                            {{comps.filter((c)=> c.code == bloodTypeGroupKey)[0].value}}
                                            {{comps.filter((c)=> c.code == bloodTypeRhesusKey)[0].deftitle}} {{comps.filter((c)=> c.code == bloodTypeRhesusKey)[0].title}}
                                            ({{comps.filter((c)=> c.code == bloodTypeGroupKey)[0].value}}{{comps.filter((c)=> c.code == bloodTypeRhesusKey)[0].value}})
                                        </b>
                                    </template>
                                </measurement-link-button>
                            </div>
                            <div class="col-xs-6 col-sm-3">
                                <measurement-image-button :measurement-definition-id="bloodTypeKey"></measurement-image-button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="content" v-if="pregnancyRules.needsExtraCare()">
                    <div class="col-xs-12">
                        <form>
                            <date-time v-model="eoc.firstEncounterDate"
                                       name="firstEncounterDate"
                                       type="date"
                                       :required="false"
                                       :label="$t('createPregnancyEpisodeOfCare.firstEncounterDateLabel')"
                                       :errorText="$t('createPregnancyEpisodeOfCare.firstEncounterDateErrorText')"
                                       :helper="$t('createPregnancyEpisodeOfCare.firstEncounterDateHelperText')"
                                       @updated="stateChanged"
                                       data-test="firstEncounterDate"
                                       class="online-only" />
                        </form>
                    </div>
                </div>

                <div class="content">
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="syfilisKey"
                                                    measured-icon-name="measurements"
                                                    :measured-label="$t('createPregnancyEpisodeOfCare.syfilisLabel')"
                                                    non-measured-icon-name="measurements"
                                                    :non-measured-label="$t('createPregnancyEpisodeOfCare.registerNewSyfilisLink')"
                                                    create-measurement-route-name="eoccreate create measurement"
                                                    return-to-name="createpregnancyepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="malariaKey"
                                                    measured-icon-name="measurements"
                                                    :measured-label="$t('createPregnancyEpisodeOfCare.malariaLabel')"
                                                    non-measured-icon-name="measurements"
                                                    :non-measured-label="$t('createPregnancyEpisodeOfCare.registerNewMalariaLink')"
                                                    create-measurement-route-name="eoccreate create measurement"
                                                    return-to-name="createpregnancyepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="hivKey"
                                                    measured-icon-name="measurements"
                                                    :measured-label="$t('createPregnancyEpisodeOfCare.hivLabel')"
                                                    non-measured-icon-name="measurements"
                                                    :non-measured-label="$t('createPregnancyEpisodeOfCare.registerNewHivLink')"
                                                    create-measurement-route-name="eoccreate create measurement"
                                                    return-to-name="createpregnancyepisodeofcare">
                        </measurement-link-button>
                    </div>
                    <div class="col-xs-6 col-sm-3">
                        <measurement-link-button :measurement-definition-id="hepatitisCKey"
                                                    measured-icon-name="measurements"
                                                    :measured-label="$t('createPregnancyEpisodeOfCare.hepatitisCLabel')"
                                                    non-measured-icon-name="measurements"
                                                    :non-measured-label="$t('createPregnancyEpisodeOfCare.registerNewHepatitisCLink')"
                                                    create-measurement-route-name="eoccreate create measurement"
                                                    return-to-name="createpregnancyepisodeofcare">
                        </measurement-link-button>
                    </div>
                </div>

                <div class="content">
                    <div class="col-xs-6">
                        <button class="btn btn__text btn__text--black-border btn__text--short left"
                                type="button" @click="closeDialog" data-test="cancel-button">
                            <h4>{{$t('general.close')}}</h4>
                        </button>
                    </div>
                    <div class="col-xs-6">
                        <button class="btn btn__text btn__text--green right online-only" :class="{ disabled : !isValid }" :disabled="!isValid"
                                type="button" @click="createEpisodeOfCare" data-test="add-button">
                            <h4>{{$t('general.create')}}</h4>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>

<script setup>
    import DateTime from '@/Components/Shared/Form/DateTime.vue';
    import MeasurementLinkButton from './MeasurementLinkButton.vue';
    import MeasurementImageButton from './MeasurementImageButton.vue';

    import { computed, reactive, ref, watch } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js'
    import { usePregnancyEpisodeOfCareService } from '@/Components/HealthData/EpisodeOfCare/PregnancyEpisodeOfCareService.js';
    import { usePregnancyEpisodeOfCareRules } from '@/Components/HealthData/EpisodeOfCare/PregnancyEpisodeOfCareRules.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const router = useRouter();
    const { stateChanged, isValid } = useFormValidator();
    const service = usePregnancyEpisodeOfCareService();
    const pregnancyRules = usePregnancyEpisodeOfCareRules();
    const caller = useExceptionwrappedCaller();

    const bloodTypeKey = 'bloodType';
    const bloodTypeGroupKey = 'bloodTypeGroup';
    const bloodTypeRhesusKey = 'bloodTypeRhesus';
    const syfilisKey = 'venerealDiseaseResearchLabTest';
    const malariaKey = 'malariaRapidDiagnostic';
    const hivKey = 'providerInitiatedTreatmentAndCare';
    const hepatitisCKey = 'hepatitisC';

    const isClinician = computed(() => store.getters.isClinician);

    let eoc = reactive({
        termDate: null,
        firstEncounterDate: null,
    });

    let termDateChanged = ref(false);

    const closeDialog = () => router.push({ name: 'episodeofcare' });

    const updateAntiDDate = () => {
        let x = pregnancyRules.suggestAntiDInjectionDate(eoc.termDate);
        if (x) {
            eoc.firstEncounterDate = new Date(x).toISOString();
            console.log('anti-D date changed', eoc.firstEncounterDate);
        }
        termDateChanged.value = false;
    }

    watch(() => eoc.termDate, (nv, ov) => {
        if (nv == ov || !pregnancyRules.needsExtraCare()) return;
        if (!eoc.firstEncounterDate)
            updateAntiDDate();
        else
            termDateChanged.value = true;
    });

    const createEpisodeOfCare = async () => {
        await caller.call(async () => {
            await service.createPregnancyEpisodeOfCare(eoc.termDate, eoc.firstEncounterDate);
            closeDialog();
        });
    }
</script>
