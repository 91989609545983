<template>
    <bar title="Admin Dashboard" :full="true" />
    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <button type="button"
                        class="btn btn__text btn__text--large btn__text--green"
                        @click="showRegisterClinician">
                    Register New Clinician
                </button>
            </div>
            <div class="col-xs-12">
                <button type="button"
                        class="btn btn__text btn__text--large btn__text--green"
                        @click="showCreateClinic">
                    Create New Clinic
                </button>
            </div>
            <div class="col-xs-12">
                <button type="button"
                        class="btn btn__text btn__text--large btn__text--green"
                        @click="showCreateOrganization">
                    Create New Organization
                </button>
            </div>        
            <div class="col-xs-12">
                <button type="button"
                        class="btn btn__text btn__text--large btn__text--green"
                        @click="showImportMeasurementDefinitions">
                    Import Measurement Definitions
                </button>
            </div>        
            <div class="col-xs-12">
                <button type="button"
                        class="btn btn__text btn__text--large btn__text--green"
                        @click="showLocalMedicationManagement">
                    Local Medication
                </button>
            </div>        
        </div>
    </div>
</template>


<script>
    //import { ref, reactive, onMounted } from 'vue';
    import { useRouter } from 'vue-router';

    export default {
        name: 'AdminDashboard',
        setup() {
            const router = useRouter();

            const showRegisterClinician = () => {
                router.push({ name: 'register clinician' });
            }

            const showCreateClinic = () => {
                router.push({ name: 'create clinic' });
            }

            const showCreateOrganization = () => {
                router.push({ name: 'create organization' });
            }

            const showImportMeasurementDefinitions = () => {
                router.push({ name: 'import measurement definitions' });
            }

            const showLocalMedicationManagement = () => {
                router.push({ name: 'list local combo medications' });
            }

            return {
                showRegisterClinician,
                showCreateClinic,
                showCreateOrganization,
                showImportMeasurementDefinitions,
                showLocalMedicationManagement,
            };
        }
    }
</script>