<template>
    <bar title="Import Measurement Definitions" @close="backToDashboard" />
    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <form class="large-text">
                    <text-area v-model="model.definition"
                               label="JSON array w/definitions"></text-area>
                    <button type="button" class="btn btn__text--green btn_text--round" @click="importDefinitions">Import</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
    import TextArea from '@/Components/Shared/Form/TextArea';

    import { computed, onMounted, reactive } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useMeasurementDefinitionService } from '@/Components/HealthData/Measurements/MeasurementDefinitionService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const router = useRouter();
    const measurementDefinitionService = useMeasurementDefinitionService();
    const caller = useExceptionwrappedCaller();

    const mDefs = computed(() => store.getters.getMeasurementDefinitions);

    onMounted(async () => {
        await caller.call(async () => {
            await measurementDefinitionService.loadMeasurementDefinitions();
        });
    });

    let model = reactive({ definition: '' });

    const importDefinitions = async () => {
        let defs = model.definition.replace(/\/\/[^\n"]*\n/g, () => {
            return ""
        });

        try {
            console.log('Try parsing definition', model.definition, defs);
            let json = JSON.parse(defs);
            console.log('definitions parsed', json.length, json);
            let existing = [];
            json.forEach((d) => {
                if (mDefs.value.reduce((p, c) => d.valueDefinition.codingCode == c.valueDefinition.codingCode || p, false)) {
                    existing.push(d);
                }
            });

            if (existing.length > 0)
                alert(`${existing.length} definitions already exists\n\n${existing.reduce((p, c) => p + (p.length > 0 ? ', ' : '') + c.valueDefinition.codingCode, '')}`);

            if (confirm(`Do you wish to import ${json.length - existing.length} measurement definitions?`)) {
                await caller.call(async () => {
                    const definitionsData = json.filter((d) => !existing.includes(d));
                    await measurementDefinitionService.importMeasurementDefinitions(definitionsData);
                });
            }
        }
        catch (e) {
            if (e.message.includes('at position')) {
                let m = (/\d+/gi).exec(e.message);
                let n = Number(m[0]);
                alert('Error when importing definitions\n\n' + e + '\n\n' + defs.substr(Math.max(n - 100, 0), 200));
            }
            else
                alert('Error when importing definitions\n\n' + e);
        }
    }

    const backToDashboard = () => router.push({ name: 'admin' });
</script>

<style type="text/css">
    .large-text .textarea textarea {
        height: 500px;
        min-height: 10vh;
        max-height: 70vh;
    }
</style>
