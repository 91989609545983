<template>
    <div class="overlay overlay--patient" id="editMeasurement">
        <router-view v-slot="{ Component }">
            <component :is="Component" @canceled="closeDialog" />
        </router-view>
    </div>
</template>

<script setup>
    import { useRouter } from 'vue-router';

    const router = useRouter();

    const closeDialog = () => router.push({ name: 'measurements' });
</script>