<template>
    <bar title="Local Medication" @close="backToDashboard" />
    <div class="container">
        <div class="row list__clickable pb-medium">
            <div class="col-xs-12">
                <h2>http://mypersonalhealthbank.com/tz/meds</h2>
            </div>

            <div class="col-xs-3">Code</div>
            <div class="col-xs-7">Text</div>
            <div class="col-xs-2">&nbsp;</div>

            <template v-for="code in codes" :key="code.code">
                <div @click="viewComboMedication(code)">
                    <div class="col-xs-3">{{code.code}}</div>
                    <div class="col-xs-8">{{code.text}}</div>
                    <div class="col-xs-1">
                        <button type="button">
                            <div class="svg-container">
                                <svg-icon icon-name="eye"></svg-icon>
                            </div>
                        </button>
                    </div>
                </div>
            </template>
        </div>
        <div class="row">
            <div class="col-xs-12">
                <button type="button" class="btn__text btn__text--green" @click="addComboMedication">Add new combo medication</button>
            </div>
        </div>
    </div>
    <router-view></router-view>
</template>


<script>
    import { ref, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import termAdminService from '@/Components/Terminology/Shared/CodedConceptAdminService.js';

    export default {
        name: 'AdminLocalComboMedication',
        setup() {
            const router = useRouter();

            const codes = ref([]);

            onMounted(async () => {
                codes.value = await termAdminService.getCodesForSystem('http://mypersonalhealthbank.com/tz/meds');
            });

            const viewComboMedication = (code) => router.push({ name: 'edit local combo medication', params: { codingCode: code.code.replace('+','-') } });

            const addComboMedication = () => router.push({ name: 'create local combo medication' });

            const backToDashboard = () => router.back();

            return {
                codes,
                viewComboMedication,
                addComboMedication,
                backToDashboard,
            };
        }
    }
</script>