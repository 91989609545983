<template>
    <div id="pushDashboard" class="overlay overlay--dashboard">
        <bar :title="$t('pushDashboard.title')" @close="closeDialog" />

        <div class="container">
            <div class="row pt-small">
                <div class="col-xs-push-1 col-xs-10 center-text">
                    <h5 class="text-gray">{{$t('pushDashboard.subTitle')}}</h5>
                </div>
            </div>

            <div class="row pt-large">
                <div class="col-xs-4 col-sm-3">
                    <CardButton link-to="/dashboard/push/donorrequest" color="grey" size="medium" svg="pushnotification" :text="$t('pushDashboard.viewDonorRequestButton')" data-test="donor-request-button" />
                </div>
                <div class="col-xs-4 col-sm-3">
                    <CardButton link-to="/dashboard/push/generalmessage" color="grey" size="medium" svg="pushmessage" :text="$t('pushDashboard.viewGeneralMessageButton')" data-test="general-message-button" />
                </div>
            </div>
        </div>

        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>

<script setup>
    import CardButton from '@/Components/Shared/Buttons/CardButton.vue';

    import { useRouter } from 'vue-router'
    const router = useRouter();
    const closeDialog = () => router.push({ name: 'dashboard' });
</script>