<template>
    <div v-if="isClinician" class="overlay overlay--patient" id="createMeasurement">
        <router-view v-slot="{ Component }">
            <component :is="Component" @canceled="closeDialog" @created="createMeasurement" />
        </router-view>
    </div>
</template>

<script setup>
    import { computed, defineProps } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useMeasurementService } from '@/Components/HealthData/Measurements/MeasurementService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const props = defineProps({
        returnToName: { type: String, default: null }
    });

    const retName = computed(() => props.returnToName); // route.query.returnToName;

    const store = useStore();
    const router = useRouter();
    const measurementService = useMeasurementService();
    const caller = useExceptionwrappedCaller();

    const isClinician = computed(() => store.getters.isClinician);

    const closeDialog = () => router.push({ name: retName.value || 'measurements' });

    const createMeasurement = async (measurementValue) => {
        await caller.call(async () => {
            await measurementService.createMeasurement(measurementValue);

            closeDialog();
        });
    }
</script>