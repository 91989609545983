<template>
    <div id="adminConsole" style="margin-top:-100px">
        <router-view></router-view>
    </div>
</template>


<script>
    export default {
        name: 'Admin',
    }
</script>