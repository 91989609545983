<template>
    <div class="container pb-medium online-only" id="measurements-summary">
        <div class="row">
            <div class="col-xs-12">
                <div class="col-xs-3">
                    <div class="measurement" @click="showCreateMeasurement(HEIGHTKEY)">
                        <div class="measurement__title">
                            <span>{{$t('measurementsSummary.heightButton')}}</span>
                        </div>
                        <div class="measurement__container">
                            <div class="center-y">
                                <div class="measurement__container--value-set">
                                    <div class="measurement__container--value" v-if="newestHeight"><b>{{newestHeight.measurementValue.value.value}}</b></div>
                                    <div class="measurement__container--value" v-else>-</div>
                                    <div class="measurement__container--type" v-if="newestHeight">{{newestHeight.measurementValue.value.definition.unitText}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-3">
                    <div class="measurement" @click="showCreateMeasurement(WEIGHTKEY)">
                        <div class="measurement__title">
                            <span>{{$t('measurementsSummary.weightButton')}}</span>
                        </div>
                        <div class="measurement__container">
                            <div class="center-y">
                                <div class="measurement__container--value-set">
                                    <div class="measurement__container--value" v-if="newestWeight"><b>{{newestWeight.measurementValue.value.value}}</b></div>
                                    <div class="measurement__container--value" v-else>-</div>
                                    <div class="measurement__container--type" v-if="newestWeight">{{newestWeight.measurementValue.value.definition.unitText}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-3">
                    <div class="measurement" @click="showCreateMeasurement(BLOODTYPEKEY)">
                        <div class="measurement__title">
                            <span>{{$t('measurementsSummary.bloodTypeButton')}}</span>
                        </div>
                        <div class="measurement__container">
                            <div class="center-y">
                                <div class="measurement__container--value-set">
                                    <div class="measurement__container--value" v-if="newestBloodType"><b>{{bloodGroupValue}}{{bloodRhesusValue}}</b></div>
                                </div>
                                <div class="measurement__container--value-set">
                                    <div class="measurement__container--type" v-if="newestBloodType">
                                        {{bloodGroupValueTitle}}
                                        {{bloodGroupUnits}}
                                    </div>
                                    <div class="measurement__container--value" v-else>-</div>
                                    <div class="measurement__container--value" v-if="newestBloodType">
                                        {{bloodRhesusTitle}}
                                        {{bloodRhesusValueTitle}}
                                        {{bloodRhesusUnits}}
                                    </div>
                                    <div class="measurement__container--value" v-else>-</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-3">
                    <div class="measurement" @click="showCreateMeasurement(BLOODPRESSUREKEY)">
                        <div class="measurement__title">
                            <span>{{$t('measurementsSummary.bloodPressureButton')}}</span>
                        </div>
                        <div class="measurement__container">
                            <div class="center-y">
                                <div class="measurement__container--value-set">
                                    <div class="measurement__container--value" v-if="newestBloodPressure"><b>{{bloodPressureSystolicValue}}</b></div>
                                    <div class="measurement__container--value" v-else>-</div>
                                    <div class="measurement__container--type" v-if="newestBloodPressure">
                                        {{bloodPressureSystolicUnits}}
                                    </div>
                                </div>
                                <div class="measurement__container--value-set">
                                    <div class="measurement__container--value" v-if="newestBloodPressure"><b>{{bloodPressureDiastolicValue}}</b></div>
                                    <div class="measurement__container--value" v-else>-</div>
                                    <div class="measurement__container--type" v-if="newestBloodPressure">
                                        {{bloodPressureDiastolicUnits}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-3">
                    <div class="measurement" @click="showCreateMeasurement(TEMPERATUREKEY)">
                        <div class="measurement__title">
                            <span>{{$t('measurementsSummary.temperatureButton')}}</span>
                        </div>
                        <div class="measurement__container">
                            <div class="center-y">
                                <div class="measurement__container--value-set">
                                    <div class="measurement__container--value" v-if="newestTemperature"><b>{{newestTemperature.measurementValue.value.value}}</b></div>
                                    <div class="measurement__container--value" v-else>-</div>
                                    <div class="measurement__container--type" v-if="newestTemperature">{{newestTemperature.measurementValue.value.definition.unitText}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-3">
                    <div class="measurement" @click="showCreateMeasurement(BLOODSUGARKEY)">
                        <div class="measurement__title">
                            <span>{{$t('measurementsSummary.bloodSugarButton')}}</span>
                        </div>
                        <div class="measurement__container">
                            <div class="center-y">
                                <div class="measurement__container--value-set">
                                    <div class="measurement__container--value" v-if="newestBloodSugar"><b>{{newestBloodSugar.measurementValue.value.value}}</b></div>
                                    <div class="measurement__container--value" v-else>-</div>
                                    <div class="measurement__container--type" v-if="newestBloodSugar">{{newestBloodSugar.measurementValue.value.definition.unitText}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-xs-3" v-if="isClinician">
                    <div class="measurement" @click="showOtherMeasurements()">
                        <div class="measurement__title">
                            <span>{{$t('measurementsSummary.otherMeasurementsButton')}}</span>
                        </div>
                        <div class="measurement__container">
                            <div class="center-y">
                                <div class="measurement__container--value-set">
                                    <div class="measurement__container--value" style="font-size:4rem;font-weight:bold">+</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import {
        useMeasurementHelperService,
        BLOODTYPEKEY,
        BLOODPRESSUREKEY,
        BLOODSUGARKEY,
        TEMPERATUREKEY,
        WEIGHTKEY,
        HEIGHTKEY
    } from '@/Components/HealthData/Measurements/MeasurementHelperService.js';

    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const {
        newestWeight,
        newestHeight,
        newestTemperature,
        newestBloodPressure,
        newestBloodPressureSystolicComponent,
        newestBloodPressureDiastolicComponent,
        newestBloodSugar,
        newestBloodType,
        newestBloodTypeGroupComponent,
        newestBloodTypeRhesusComponent
    } = useMeasurementHelperService();

    const isClinician = computed(() => store.getters.isClinician);

    const showOtherMeasurements = () => {
        if (isClinician.value)
            router.push({ name: 'list other measurements' });
    }

    const showCreateMeasurement = (id) => {
        if (isClinician.value)
            router.push({
                name: 'create measurement component',
                params: {
                    measurementDefinitionId: id
                }
            });
    }

    const nullCheckWrapper = (nc, a) => { if (!nc) return null; return a() }

    const bloodPressureSystolicValue = computed(() => nullCheckWrapper(newestBloodPressureSystolicComponent.value, () => newestBloodPressureSystolicComponent.value.value));
    const bloodPressureSystolicUnits = computed(() => nullCheckWrapper(newestBloodPressureSystolicComponent.value, () => newestBloodPressureSystolicComponent.value.definition.unitText));

    const bloodPressureDiastolicValue = computed(() => nullCheckWrapper(newestBloodPressureDiastolicComponent.value, () => newestBloodPressureDiastolicComponent.value.value));
    const bloodPressureDiastolicUnits = computed(() => nullCheckWrapper(newestBloodPressureDiastolicComponent.value, () => newestBloodPressureDiastolicComponent.value.definition.unitText));

    const bloodGroupValue = computed(() => nullCheckWrapper(newestBloodTypeGroupComponent.value, () => newestBloodTypeGroupComponent.value.value));
    const bloodGroupValueTitle = computed(() => nullCheckWrapper(newestBloodTypeGroupComponent.value, () => t(newestBloodTypeGroupComponent.value.definition.valueSetValues.filter(vs => vs.value == bloodGroupValue.value)[0].titleKey + '')));
    const bloodGroupUnits = computed(() => nullCheckWrapper(newestBloodTypeGroupComponent.value, () => newestBloodTypeGroupComponent.value.definition.unitText));

    const bloodRhesusValue = computed(() => nullCheckWrapper(newestBloodTypeRhesusComponent.value, () => newestBloodTypeRhesusComponent.value.value));
    const bloodRhesusValueTitle = computed(() => nullCheckWrapper(newestBloodTypeRhesusComponent.value, () => t(newestBloodTypeRhesusComponent.value.definition.valueSetValues.filter(vs => vs.value == bloodRhesusValue.value)[0].titleKey + '')));
    const bloodRhesusUnits = computed(() => nullCheckWrapper(newestBloodTypeRhesusComponent.value, () => newestBloodTypeRhesusComponent.value.definition.unitText));
    const bloodRhesusTitle = computed(() => nullCheckWrapper(newestBloodTypeRhesusComponent.value, () => t(newestBloodTypeRhesusComponent.value.definition.titleKey + '')));
</script>
