<template>
    <div id="patientEpisodeOfCare" class="overlay overlay--patient">
        <bar :title="$t(`episodeOfCare.title`)" @close="closeDialog" v-if="isClinician" />
        
        <EpisodeOfCareOptions v-if="isClinician"></EpisodeOfCareOptions>
        <ListEpisodesOfCare></ListEpisodesOfCare>

        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>

<script setup>
    import EpisodeOfCareOptions from '@/Components/HealthData/EpisodeOfCare/EpisodeOfCareOptions';
    import ListEpisodesOfCare from '@/Components/HealthData/EpisodeOfCare/ListEpisodesOfCare';

    import { computed, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    //import { useEpisodeOfCareService } from '@/Components/HealthData/EpisodeOfCare/EpisodeOfCareService.js';
    import { usePregnancyEpisodeOfCareService } from '@/Components/HealthData/EpisodeOfCare/PregnancyEpisodeOfCareService.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';

    const store = useStore();
    const router = useRouter();
    //const service = useEpisodeOfCareService();
    const service = usePregnancyEpisodeOfCareService();
    const caller = useExceptionwrappedCaller();

    const isClinician = computed(() => store.getters.isClinician);

    const closeDialog = () => router.push({ name: 'patient' });

    watchEffect(async () => {
        if (!store.getters.getPatientId) return;
        await caller.call(async () => {
            //await service.loadEpisodesOfCare();
            await service.loadPregnancyEpisodesOfCare();
        });
    });
</script>