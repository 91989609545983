<template>
    <div class="container" style="padding-top:0px;margin-top:-80px">
        <div class="row">
            <div class="col-xs-12" style="padding-bottom: 0px">
                <TextArea v-model="textarea" name="textarea"
                          errorText="Too short" helper="Must be at least 5 words"
                          pattern="((^|\s)[a-zøæåA-ZØÆÅ0-9]+){5,}" :required="true"
                          label="Test textarea" @updated="stateChanged" />
                <TextBox v-model="email" name="email" type="email" :required="false"
                         errorText="Invalid email address"
                         label="Test email" @updated="stateChanged" />
                <TextBox v-model="password" name="password" type="password"
                         errorText="Too short"
                         label="Test password" @updated="stateChanged" />
                <TextBox v-model="text" name="text" label="Test text" :required="true" pattern=".{3,}" @updated="stateChanged" />
                <DropDownBox v-model="dropdown" name="dropdown"
                             errorText="Please select an option from the list"
                             pattern=".+" :elements="dropdownchoices"
                             @updated="stateChanged">
                </DropDownBox>

                <DateTime v-model="date" name="date" label="Select a date"
                          type="date" errorText="Please select a date"
                          @updated="stateChanged" />
                <DateTime v-model="time" name="time" label="Select a time"
                          type="time" errorText="Please select a time"
                          @updated="stateChanged" />

                <div>
                    <RadioList v-model="radiolist" name="radio" label="Radiolist test"
                               :elements="radiolistchoices"
                               errorText="Please choose one" @updated="stateChanged" />
                </div>
                <Number v-model="number" name="number" label="Test number" />
                <phone-number v-model="phoneNumber" name="phoneNumber" label="Test phonenumber" />

                <CheckBox v-model="checkDef" name="checkDef" label="Test default checkbox"
                          type="checkbox"
                          errorText="Must be checked"
                          helper="This must be selected"
                          :must-be-state="true"
                          @updated="stateChanged" />
                <CheckBox v-model="check" name="check" label="Test checkbox"
                          type="checkbox"
                          errorText="Must be checked"
                          helper="This must be selected"
                          :must-be-state="'yes'"
                          :falseValue="'no'"
                          :trueValue="'yes'"
                          @updated="stateChanged" />
                <CheckBox v-model="switchBox" name="switch" label="Test switch"
                          type="switch"
                          errorText="Must be checked"
                          helper="This must be selected"
                          :falseValue="0"
                          :trueValue="1"
                          @updated="stateChanged" />
            </div>

            <div class="col-xs-12">
                <button type="button" class="btn btn__round--green"
                        :class="{disabled:!isValid}"
                        :disabled="!isValid"
                        style="padding: 10px; color: white; border-radius: 50px"
                        @click="saySomething">
                    Do something
                </button>
            </div>

            <div class="col-xs-12" style="padding-top:30px;">
                textarea: {{textarea}}<br />
                email: {{email}}<br />
                password: {{password}}<br />
                text: {{text}}<br />
                checkDef: {{checkDef}}<br />
                check: {{check}}<br />
                switch: {{switchBox}}<br />
                dropdown: {{dropdown}}<br />
                date: {{date}}<br />
                time: {{time}}<br />
                radio: {{radiolist}}<br />
                number: {{number}}<br />
                phoneNumber: {{phoneNumber}}<br />
                isValid: {{isValid}}<br />
                invalid fields: {{invalidFields}}<br />
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref } from 'vue';
    import { useFormValidator } from '../Shared/Form/FormValidator.js';
    import TextArea from '../Shared/Form/TextArea.vue';
    import TextBox from '../Shared/Form/TextBox.vue';
    import CheckBox from '../Shared/Form/CheckBox.vue';
    import DropDownBox from '../Shared/Form/DropDownBox.vue';
    import DateTime from '../Shared/Form/DateTime.vue';
    import RadioList from '../Shared/Form/RadioList.vue';
    import Number from '../Shared/Form/Number.vue';
    import PhoneNumber from '../Shared/Form/PhoneNumber.vue';

    const { stateChanged, isValid, invalidFields } = useFormValidator();

    const saySomething = () => alert('hey');

    let textarea = ref('');
    let email = ref('');
    let password = ref('');
    let text = ref('');
    let checkDef = ref(false);
    let check = ref('no');
    let switchBox = ref(0);
    let dropdown = ref('');
    let date = ref('2022-01-02T10:20:30.000Z');
    let time = ref('2022-01-02T10:20:30.000Z');
    //let time = ref('10:20:30.000Z');
    //let time = ref('10:20');
    let radiolist = ref('');
    let number = ref(0);
    let phoneNumber = ref('+4512345678');

    let dropdownchoices = [
        { key: '', value: 'Choose', selected: true },
        { key: '1', value: 'First one' },
        { key: '2', value: 'Second one' },
        { key: '3', value: 'Third one' },
    ];

    let radiolistchoices = [
        { value: '1', label: 'First one' },
        { value: '2', label: 'Second one' },
        { value: '3', label: 'Third one' },
        { value: '4', label: 'Fourth one' },
    ];
</script>