<template>
	<button :class="'btn btn__card btn__card--' + size + ' ' + color" @click="router.push(linkTo)">
		<div class="svg-container">
			<svg xmlns="http://www.w3.org/2000/svg" :style="svgStyle">
				<use :xlink:href="'#'+svg" />
			</svg>
		</div>
		<div class="text-container">
			<span class="only-small-devices">{{text}}</span>
			<span class="not-small-devices">{{nodashtext}}</span>
		</div>
	</button>
</template>

<script>
	import { useRouter } from 'vue-router';
    import { computed } from 'vue';

	export default {
		name: 'CardButton',
		props: {
			linkTo: String,
			color: String,
			size: String,
			text: String,
			svg: String,
			svgStyle: { type: String, default: null },
		},
		setup(props) {
			let router = useRouter();
			let nodashtext = computed(() => props.text.replace('-', ''));

			return {
                nodashtext,
				router
            }
        }
    }
</script>