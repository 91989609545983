<template>
    <bar title="Register Clinician" @close="backToDashboard" />
    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <div>
                    <form>
                        <clinic-search v-model="m.clinicId"
                                  name="clinicId"
                                  label="Choose clinic"
                                  errorText="Clinic must be selected"
                                  helper="Search for a clinic by entering part of its name and then select the desired one from the list of results"
                                  placeholder="Enter the clinic name..."
                                  @updated="stateChanged" />

                        <text-box v-model="m.givenNames"
                                  name="givennames"
                                  label="Given names"
                                  @updated="stateChanged" />
                        <text-box v-model="m.familyName"
                                  name="familyname"
                                  label="Familyname"
                                  @updated="stateChanged" />
                        <text-box v-model="m.email"
                                  name="email"
                                  type="email"
                                  label="E-mail"
                                  @updated="stateChanged" />
                        <text-box v-model="m.phone"
                                  name="phone"
                                  label="Phone"
                                  @updated="stateChanged" />
                        <text-box v-model="m.password"
                                  name="password"
                                  type="password"
                                  label="Password"
                                  @updated="passwordStateChanged" />
                        <text-box v-model="m.confirmPassword"
                                  name="confirmPassword"
                                  type="password"
                                  label="Confirm Password"
                                  :pattern="passwordConfirmPattern"
                                  :pattern-case-sensitive="true"
                                  @updated="stateChanged" />

                        <button type="button" 
                                class="btn btn__text btn__text--large btn__text--green btn__text--short right" 
                                :class="{ locked: !isValid }"
                                @click="createClinician" 
                                :disabled="!isValid">Create</button>
                    </form>
                </div>
                <div v-show="status">{{status}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, reactive } from 'vue';
    import { useRouter } from 'vue-router';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator';
    import TextBox from '@/Components/Shared/Form/TextBox';
    import ClinicSearch from '@/Components/Shared/Form/ClinicSearch';
    import { makeRegexSafe } from '@/Components/Shared/RegexUtils.js';
    import service from '@/Components/Organizations/Clinician/ClinicianService';

    export default {
        name: 'RegisterClinician',
        components: {
            TextBox,
            ClinicSearch,
        },
        setup() {
            const router = useRouter();
            let { stateChanged, isValid } = useFormValidator();
            let m = reactive({
                givenNames: '',
                familyName: '',
                email: '',
                password: '',
                confirmPassword: '',
                phone: '',
                clinicId: '',
            });
            let passwordConfirmPattern = ref('');
            let status = ref('');

            const passwordStateChanged = (info) => {
                stateChanged(info);
                passwordConfirmPattern.value = '^' + makeRegexSafe(m.password) + '$';
            }

            const createClinician = async () => {
                try {
                    await service.registerClinician(
                        m.givenNames,
                        m.familyName,
                        m.email,
                        m.password,
                        m.phone,
                        m.clinicId,
                        'en');

                    status.value = "Clinician created.";

                    m.givenNames = '';
                    m.familyName = '';
                    m.email = '';
                    m.password = '';
                    m.confirmPassword = '';
                    m.phone = '';
                    m.clinicId = '';
                }
                catch (e) {
                    console.error(e.message || e);
                }
            }

            const backToDashboard = () => {
                router.push({ name: 'admin' });
            }

            return {
                m,
                status,
                passwordConfirmPattern,
                stateChanged,
                passwordStateChanged,
                isValid,
                createClinician,
                backToDashboard,
            };
        }
    }
</script>