import dispatcher from '@/Connections/Dispatcher.js';

const createComboMedication = async (comboCode) => {
    return await dispatcher.postToApi(`medicinecodes/combo`, comboCode);
}

const getCodesForSystem = async (codingSystem) => {
    return await dispatcher.getFromApi(`medicinecodes/system?codingSystem=${codingSystem}`);
}

export default {
    name: "CodedConceptAdminApi",
    createComboMedication,
    getCodesForSystem,
}