<template>
    <bar title="Create Organization" @close="backToDashboard" />
    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <div>
                    <form>
                        <text-box v-model="m.name"
                                  name="name"
                                  label="Name"
                                  @updated="stateChanged" />
                        <text-area v-model="m.address"
                                  name="address"
                                  label="Address"
                                  @updated="stateChanged" />
                        <text-box v-model="m.country"
                                  name="country"
                                  label="Country"
                                  @updated="stateChanged" />
                        <text-box v-model="m.email"
                                  name="email"
                                  type="email"
                                  label="E-mail"
                                  @updated="stateChanged" />
                        <text-box v-model="m.phone"
                                  name="phone"
                                  label="Phone"
                                  @updated="stateChanged" />

                        <text-box v-model="m.diagnosisCodeSystem"
                                  name="diagnosisCodeSystem"
                                  label="Diagnosis Code System"
                                  @updated="stateChanged" />
                        <text-box v-model="m.diagnosisCodeSystemDisplay"
                                  name="diagnosisCodeSystemDisplay"
                                  label="Diagnosis Code System Display name"
                                  @updated="stateChanged" />
                        <text-box v-model="m.diagnosisCodeSystemRoots"
                                  name="diagnosisCodeSystemRoots"
                                  label="Diagnosis Code System Roots"
                                  @updated="stateChanged" />

                        <text-box v-model="m.medicationCodeSystem"
                                  name="medicationCodeSystem"
                                  label="Medication Code System"
                                  @updated="stateChanged" />
                        <text-box v-model="m.medicationCodeSystemDisplay"
                                  name="medicationCodeSystemDisplay"
                                  label="Medication Code System Display name"
                                  @updated="stateChanged" />
                        <text-box v-model="m.medicationCodeSystemRoots"
                                  name="medicationCodeSystemRoots"
                                  label="Medication Code System Roots"
                                  @updated="stateChanged" />

                        <text-box v-model="m.localMedicationCodeSystem"
                                  name="localMedicationCodeSystem"
                                  label="Local Medication Code System"
                                  @updated="stateChanged" />
                        <text-box v-model="m.localMedicationCodeSystemDisplay"
                                  name="localMedicationCodeSystemDisplay"
                                  label="Local Medication Code System Display name"
                                  @updated="stateChanged" />
                        <text-box v-model="m.localMedicationCodeSystemRoots"
                                  name="localMedicationCodeSystemRoots"
                                  label="Local Medication Code System Roots"
                                  @updated="stateChanged" />

                        <text-box v-model="m.immunizationCodeSystem"
                                  name="immunizationCodeSystem"
                                  label="Immunization Code System"
                                  @updated="stateChanged" />
                        <text-box v-model="m.immunizationCodeSystemDisplay"
                                  name="immunizationCodeSystemDisplay"
                                  label="Immunization Code System Display name"
                                  @updated="stateChanged" />
                        <text-box v-model="m.immunizationCodeSystemRoots"
                                  name="immunizationCodeSystemRoots"
                                  label="Immunization Code System Roots"
                                  @updated="stateChanged" />

                        <button type="button" 
                                class="btn btn__text btn__text--large btn__text--green btn__text--short right" 
                                @click="createOrganization" 
                                :disabled="!isValid">Create</button>
                    </form>
                </div>
                <div v-show="status">{{status}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, reactive } from 'vue';
    import { useRouter } from 'vue-router';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator';
    import TextBox from '@/Components/Shared/Form/TextBox';
    import TextArea from '@/Components/Shared/Form/TextArea';
    import service from '@/Components/Organizations/Organization/OrganizationService.js';

    export default {
        name: 'CreateClinic',
        components: {
            TextBox,
            TextArea,
        },
        setup() {
            const router = useRouter();
            let { stateChanged, isValid } = useFormValidator();
            let m = reactive({
                name: '',
                address: '',
                country: '',
                email: '',
                phone: '',
                diagnosisCodeSystem: '',
                diagnosisCodeSystemDisplay: '',
                diagnosisCodeSystemRoots: '',
                medicationCodeSystem: '',
                medicationCodeSystemDisplay: '',
                medicationCodeSystemRoots: '',
                localMedicationCodeSystem: '',
                localMedicationCodeSystemDisplay: '',
                localMedicationCodeSystemRoots: '',
                immunizationCodeSystem: '',
                immunizationCodeSystemDisplay: '',
                immunizationCodeSystemRoots: '',
            });
            let status = ref('');

            const createOrganization = async () => {
                try {
                    await service.createOrganization(
                        m.name,
                        m.address,
                        m.country,
                        m.email,
                        m.phone,
                        m.diagnosisCodeSystem,
                        m.diagnosisCodeSystemDisplay,
                        m.diagnosisCodeSystemRoots,
                        m.medicationCodeSystem,
                        m.medicationCodeSystemDisplay,
                        m.medicationCodeSystemRoots,
                        m.localMedicationCodeSystem,
                        m.localMedicationCodeSystemDisplay,
                        m.localMedicationCodeSystemRoots,
                        m.immunizationCodeSystem,
                        m.immunizationCodeSystemDisplay,
                        m.immunizationCodeSystemRoots);

                    status.value = "Organization created.";

                    m.name = '';
                    m.address = '';
                    m.country = '';
                    m.email = '';
                    m.phone = '';
                    m.diagnosisCodeSystem = '';
                    m.diagnosisCodeSystemDisplay = '';
                    m.diagnosisCodeSystemRoots = '';
                    m.medicationCodeSystem = '';
                    m.medicationCodeSystemDisplay = '';
                    m.medicationCodeSystemRoots = '';
                    m.localMedicationCodeSystem = '';
                    m.localMedicationCodeSystemDisplay = '';
                    m.localMedicationCodeSystemRoots = '';
                    m.immunizationCodeSystem = '';
                    m.immunizationCodeSystemDisplay = '';
                    m.immunizationCodeSystemRoots = '';
                }
                catch (e) {
                    console.error(e.message || e);
                }
            }

            const backToDashboard = () => {
                router.push({ name: 'admin' });
            }

            return {
                m,
                status,
                stateChanged,
                isValid,
                createOrganization,
                backToDashboard,
            };
        }
    }
</script>