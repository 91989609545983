<template>
    <bar :title="title" @close="backToList" />

    <!-- Find medications for the combo - must be repeatable -->
    <coded-concept-search v-model="search" code-type="medicinecodes" @codeselected="codeSelected" v-if="!codingCode"></coded-concept-search>

    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <h2>Selected ICD11 codes</h2>
            </div>
            <div class="col-xs-12 list__clickable" style="padding-right:15px">
                <template v-for="code in model.selectedCodes" :key="code.code">
                    <div class="row">
                        <div class="col-xs-3">{{code.code}}</div>
                        <div class="col-xs-6">{{code.text}}</div>
                        <div class="col-xs-3" style="text-align:right;overflow:visible">
                            <button type="button" @click="removeCode(code)" v-if="!codingCode" class="btn__text-icon--short" style="width:30px;height:30px">
                                <div class="svg-container" style="border:none"><svg-icon icon-name="cross"></svg-icon></div>
                            </button>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12 pt-medium pb-small">
                <h2>Properties of combo code</h2>
            </div>
            <div class="col-xs-12">
                <form>
                    <div class="row">
                        <div class="col-xs-12">
                            <!-- Change autogenerated text for the combo -->
                            <text-box v-model="model.text"
                                      name="codeText"
                                      label="Display Text for Code"
                                      @updated="stateChanged"
                                      :disabled="codingCode"></text-box>

                            <div><span class="label" style="display:inline-block;width:100px">Code:</span> <span class="value">{{model.code}}</span></div>
                            <div><span class="label" style="display:inline-block;width:100px">System:</span> <span class="value">{{model.system}}</span></div>
                            <div><span class="label" style="display:inline-block;width:100px">Index terms:</span> <span class="value">{{model.indexTerms.join(', ')}}</span></div>
                        </div>

                        <div class="col-xs-12 pt-medium">
                            <!-- Save the combo or cancel -->
                            <button type="button"
                                    class="btn btn__text btn__text--large btn__text--green-border btn__text--short left"
                                    @click="backToList">
                                Cancel
                            </button>
                            <button type="button"
                                    class="btn btn__text btn__text--large btn__text--green btn__text--short right"
                                    :class="{ locked : !isValid }"
                                    :disabled="!isValid"
                                    @click="createComboMedication"
                                    v-if="!codingCode">
                                Create
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <div v-show="status">{{status}}</div>
            </div>
        </div>
    </div>
</template>


<script>
    import { computed, reactive, watchEffect } from 'vue';
    //import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator';
    import CodedConceptSearch from '@/Components/Terminology/Shared/CodedConceptSearch';
    import TextBox from '@/Components/Shared/Form/TextBox';
    import termService from '@/Components/Terminology/Shared/CodedConceptSearchService.js';
    import termAdminService from '@/Components/Terminology/Shared/CodedConceptAdminService.js';

    export default {
        name: 'AdminLocalComboMedicationCreate',
        components: {
            CodedConceptSearch,
            TextBox,
        },
        props: {
            codingCode: {
                type: String,
                default: null
            }
        },
        setup(props) {
            const router = useRouter();
            let { stateChanged, isValid } = useFormValidator();

            let model = reactive({
                code: '',
                text: '',
                system: 'http://mypersonalhealthbank.com/tz/meds',
                indexTerms: [],
                selectedCodes: [],
            });

            // handle edit scenarios by reading code components of the combo code
            watchEffect(async () => {
                if (props.codingCode) {
                    console.log('EDIT COMBO CODE:', props.codingCode, props.codingCode.replace('-','+'));
                    //TODO: load data for the combo code and hydrate selectedCodes
                    let comboCode = await termService.getCodes('medicinecodes', props.codingCode.replace('-','+'));
                    if (!comboCode || comboCode.length == 0) {
                        alert('Missing data. Combination code does not exist!');
                        return;
                    }

                    model.code = comboCode[0].code;
                    model.text = comboCode[0].text;
                    model.system = comboCode[0].system;
                    model.indexTerms = comboCode[0].indexTerms;

                    // load data about each component in the combo code
                    let selectedCodes = comboCode[0].code.split('+');
                    selectedCodes.forEach(async (c) => {
                        let code = await termService.getCodes('medicinecodes', c);
                        if (code) {
                            model.selectedCodes.push(code[0]);
                        }
                    });
                }
            });

            const codeSelected = (code) => {
                if (model.selectedCodes.filter((c) => c.code == code.code).length != 0) {
                    alert('Code already exists in selected list');
                    return;
                }
                model.selectedCodes.push(code);
            }

            const removeCode = (code) => {
                if (confirm('Are you sure?')) {
                    model.selectedCodes = model.selectedCodes.filter((c) => c.code != code.code);
                }
            }

            watchEffect(() => {
                model.code = model.selectedCodes.map((v) => v.code).join('+');
                if (!model.text || !model.textModifiedByUser)
                    model.text = model.selectedCodes.map((v) => v.text).join(' + ');
                model.indexTerms = model.selectedCodes.reduce((p,c) => p.concat(c.indexTerms), []);
            });

            const createComboMedication = async () => {
                // save med to terminology
                const comboCode = {
                    code: model.code,
                    text: model.text,
                    system: model.system,
                    indexTerms: model.indexTerms,
                };
                await termAdminService.createComboMedication(comboCode);
            }

            const title = computed(() => (props.codingCode ? 'View' : 'Create') + ' Local Combo Medication');

            const backToList = () => router.back();

            return {
                stateChanged,
                isValid,
                title,
                model,
                codeSelected,
                removeCode,
                createComboMedication,
                backToList,
            };
        }
    }
</script>