import api from './CodedConceptAdminApi.js';
import { handleApiError } from '@/Shared/ErrorHandling.js';


const createComboMedication = async (comboCode) => {
    let result = await api.createComboMedication(comboCode);
    handleApiError(result, 'Error while creating combo code');
}

const getCodesForSystem = async (codingSystem) => {
    let result = await api.getCodesForSystem(codingSystem);
    handleApiError(result, 'Error while getting combo medicine codes for system');
    return result.data.medicineCodes;
}

export default {
    name: "CodedConceptAdminService",
    createComboMedication,
    getCodesForSystem,
}