<template>
    <div class="overlay overlay--patient" id="clinicianData" data-active-xxx="props.clinicianDataActive">
        <bar :title="$t('clinicianData.title')" @close="closeDialog" />
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-info">
                        <editor-content @save="saveValues(['givenNames','familyName'], 'changeName')"
                                        @cancel="cancelValues(['givenNames','familyName'])"
                                        data-test="clinician-name">
                            <template v-slot:title>
                                {{$t('clinicianData.name')}}
                            </template>
                            <template v-slot:value>
                                {{clinicianData.givenNames}} {{clinicianData.familyName}}
                            </template>
                            <template v-slot:default>
                                <text-box v-model="editing.givenNames"
                                          name="givenNames"
                                          :label="$t('clinicianData.givenNamesLabel')"
                                          :helper="$t('clinicianData.givenNamesHelper')"
                                          :placeholder="$t('clinicianData.givenNamesPlaceholder')" />
                                <text-box v-model="editing.familyName"
                                          name="familyName"
                                          :label="$t('clinicianData.familyNameLabel')"
                                          :helper="$t('clinicianData.familyNameHelper')"
                                          :placeholder="$t('clinicianData.familyNamePlaceholder')" />
                            </template>
                        </editor-content>


                        <editor-content @save="saveValues(['gender'], 'changeGender')"
                                        @cancel="cancelValues(['gender'])"
                                        data-test="clinician-gender">
                            <template v-slot:title>
                                {{$t('clinicianData.gender')}}
                            </template>
                            <template v-slot:value>
                                {{genderText(clinicianData.gender)}}
                            </template>
                            <template v-slot:default>
                                <radio-list v-model="editing.gender"
                                            name="gender"
                                            :elements="genders"
                                            :helper="$t('clinicianData.genderHelper')"
                                            :placeholder="$t('clinicianData.genderPlaceholder')" />
                            </template>
                        </editor-content>


                        <editor-content @custom="customPhoneClick"
                                        :customButton="true"
                                        data-test="clinician-phone">
                            <template v-slot:title>
                                {{$t('clinicianData.phone')}}
                            </template>
                            <template v-slot:value>
                                {{clinicianData.phone}}
                            </template>
                        </editor-content>


                        <editor-content @custom="customEmailClick"
                                        :customButton="true"
                                        data-test="clinician-email">
                            <template v-slot:title>
                                {{$t('clinicianData.email')}}
                            </template>
                            <template v-slot:value>
                                {{clinicianData.email}}
                            </template>
                        </editor-content>


                        <editor-content @save="saveValues(['description'], 'changeDescription')"
                                        @cancel="cancelValues(['description'])"
                                        data-test="clinician-description">
                            <template v-slot:title>
                                {{$t('clinicianData.description')}}
                            </template>
                            <template v-slot:value>
                                {{clinicianData.description}}
                            </template>
                            <template v-slot:default>
                                <text-area v-model="editing.description"
                                           name="description"
                                           :helper="$t('clinicianData.descriptionHelper')"
                                           :placeholder="$t('clinicianData.descriptionPlaceholder')" />
                            </template>
                        </editor-content>


                        <editor-content @save="saveValues(['image'], 'changeImage')"
                                        @cancel="cancelValues(['image'])"
                                        data-test="clinician-image">
                            <template v-slot:title>
                                {{$t('clinicianData.image')}}
                            </template>
                            <template v-slot:value>
                                <div class="image-container">
                                    <profile-image :profile-id="clinicianData.id" />
                                </div>
                            </template>
                            <template v-slot:default>
                                <div class="image-container">
                                    <profile-image :profile-id="clinicianData.id" :key="clinicianData.image" />
                                </div>
                                <label class="pb-small">{{$t('clinicianData.imageLabel')}}:</label>
                                <Upload userType="clinician" imageName="profilepicture" v-model="editing.image"></Upload>
                            </template>
                        </editor-content>

                    </div>
                </div>
            </div>
        </div>

        <router-view v-slot="{ Component, route }">
            <transition :name="route.meta.transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
    </div>
</template>


<script setup>
    import Upload from '@/Components/Shared/Form/Upload.vue';
    import TextBox from '@/Components/Shared/Form/TextBox.vue';
    import TextArea from '@/Components/Shared/Form/TextArea.vue';
    import RadioList from '@/Components/Shared/Form/RadioList.vue';
    import EditorContent from '@/Components/Shared/EditorContent.vue';

    import { defineEmits, defineProps, onMounted, onUpdated, reactive, ref, watchEffect } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator.js';
    import { useExceptionwrappedCaller } from '@/Shared/ExceptionwrappedCaller.js';
    import { useClinicianService } from '@/Components/Organizations/Clinician/ClinicianService.js';

    const props = defineProps({
        clinicianDataActive: Boolean
    });
    const emit = defineEmits(['close']);

    const store = useStore();
    const router = useRouter();
    const { t } = useI18n();
    const { stateChanged, invalidFields } = useFormValidator();
    const caller = useExceptionwrappedCaller();
    const clinicianService = useClinicianService();

    const closeDialog = () => router.push('dashboard');

    let clinicianData = reactive({
        id: store.getters.getClinicianId,
        givenNames: '',
        familyName: '',
        gender: '',
        phone: '',
        email: '',
        description: '',
        image: '',
    });

    // copy current values into a new structure used for editing mode to enable undoing edited values
    // NB: only handles flat structures - alternatively use a similar standard/lib clone-function
    let editing = reactive({});
    for (var key in clinicianData)
        editing[key] = clinicianData[key];


    watchEffect(() => {
        const sp = store.getters.getClinician;
        if (!sp) return;
        console.log('clinicianData - init local data in view:', sp);
        clinicianData.id = sp.id;
        clinicianData.givenNames = sp.givenNames;
        clinicianData.familyName = sp.familyName;
        clinicianData.gender = sp.gender;
        clinicianData.phone = sp.phone;
        clinicianData.email = sp.email;
        clinicianData.description = sp.description;
        clinicianData.image = sp.image;

        for (var key in clinicianData)
            editing[key] = clinicianData[key];
    });


    // defines the actions used for updating values in backend
    let actions = {
        async changeName(data) {
            await caller.call(async () => {
                await clinicianService.changeClinicianName(data.givenNames, data.familyName);
            });
        },
        async changeGender(data) {
            await caller.call(async () => {
                await clinicianService.changeClinicianGender(data.gender);
            });
        },
        async changeDescription(data) {
            await caller.call(async () => {
                await clinicianService.changeClinicianDescription(data.description);
            });
        },
        async changeImage(data) {
            await caller.call(async () => {
                await clinicianService.changeClinicianImage(data.image);
            });
        },
    };


    // used to check if a group of fields are valid or not (to deactivate or activate save-button)
    const checkValid = (fields) => {
        return invalidFields.value.filter((v) => fields.includes(v)).length == 0;
    };


    const saveValues = async (fields, actionName) => {
        fields.forEach((fld) => clinicianData[fld] = editing[fld]);
        if (actionName && actionName !== '' && actionName in actions) {
            let data = {};
            fields.forEach((fld) => data[fld] = clinicianData[fld]);
            await actions[actionName](data);
        }
        else {
            console.log(`${actionName} NOT FOUND!`);
        }
    }


    // cancel changes in an editor
    const cancelValues = (fields) => {
        fields.forEach((fld) => editing[fld] = clinicianData[fld]);
    }

    // Setup data for form fields which needs supporting structures...
    //
    let genders = ref([
        { value: 'None', label: 'Unspecified' },
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
    ]);

    const refreshTranslations = () => {
        genders.value.filter((g) => g.value == 'None')[0].label = t('clinicianData.nonSpecifiedGenderTitle');
        genders.value.filter((g) => g.value == 'Male')[0].label = t('clinicianData.maleGenderTitle');
        genders.value.filter((g) => g.value == 'Female')[0].label = t('clinicianData.femaleGenderTitle');
    }
    onMounted(() => refreshTranslations());
    onUpdated(() => refreshTranslations())

    const genderText = (gender) => {
        const translatedGender = genders.value.filter((g) => g.value == gender);
        if (!translatedGender || translatedGender.length == 0)
            return gender;
        return translatedGender[0].label;
    }

    const customEmailClick = () => {
        router.push({ name: 'clinician change email' })
    }

    const customPhoneClick = () => {
        router.push({ name: 'clinician change phone' })
    }
</script>
