<template>
    <div class="gray min-100" id="list-episodesofcare">
        <bar :title="$t('episodeOfCareList.title')" :full="isClinician" @close="closeDialog"></bar>
        <div class="container">
            <div class="row">
                <div class="col-xs-12">
                    <div class="table-layout">
                        <div class="head">
                            <div class="col-xs-4"><span><b>{{$t('episodeOfCareList.dateHeader')}}</b></span></div>
                            <div class="col-xs-6"><span><b>{{$t('episodeOfCareList.typeHeader')}}</b></span></div>
                            <div class="col-xs-2 center-text"><span><b>{{$t('episodeOfCareList.viewHeader')}}</b></span></div>
                        </div>
                        <div class="body">
                            <template v-for="eoc in episodesOfCare" :key="eoc.episodeOfCareId">
                                <div class="item" @click="episodeOfCareClicked(eoc)">
                                    <div class="col-xs-4">
                                        <date-display :iso-date="eoc.date" display-as="date"></date-display>
                                    </div>
                                    <div class="col-xs-6">
                                        <span data-test="episodeofcare-title">{{$t('episodeOfCareList.'+eoc.episodeOfCareType+'Label')}}</span>
                                    </div>
                                    <div class="col-xs-2 full-height">
                                        <div class="svg">
                                            <svg-icon icon-name="eye"></svg-icon>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';

    const store = useStore();
    const router = useRouter();

    const isClinician = computed(() => store.getters.isClinician);

    const closeDialog = () => router.push({ name: 'patient' });

    const episodesOfCare = computed(() => {
        let items = store.getters.getPatientEpisodesOfCare.map((a) => a);
        return items.sort((a, b) => new Date(a.date) < new Date(b.date) ? 1 : -1);
    });

    const episodeOfCareClicked = (eoc) => {
        router.push({ name: 'editpregnancyepisodeofcare', params: { id: eoc.episodeOfCareId } });
    }
</script>