<template>
    <div class="container">
        <div class="row">
            <div class="col-xs-12" style="padding-bottom: 30px">
                <div class="table-info">
                    <editor-content :valid="checkValid(['textarea'])" @save="saveValues(['textarea'])" @cancel="cancelValues(['textarea'])">
                        <template v-slot:title>
                            Description
                        </template>
                        <template v-slot:value>
                            {{current.textarea}}
                        </template>
                        <template v-slot:default>
                            <TextArea v-model="editing.textarea" name="textarea"
                                      errorText="Too short" helper="Must be at least 5 characters"
                                      pattern="\w{5,}"
                                      label="Test textarea" @updated="stateChanged" />
                        </template>
                    </editor-content>

                    <editor-content :valid="checkValid(['email'])" @save="saveValues(['email'])" @cancel="cancelValues(['email'])">
                        <template v-slot:title>
                            E-mail
                        </template>
                        <template v-slot:value>
                            {{current.email}}
                        </template>
                        <template v-slot:default>
                            <TextBox v-model="editing.email" name="email" type="email"
                                     errorText="Invalid email address"
                                     label="Test email" @updated="stateChanged" />
                        </template>
                    </editor-content>

                    <editor-content :valid="checkValid(['password','confirmPassword'])" @save="saveValues(['password'])" @cancel="cancelValues(['password'])">
                        <template v-slot:title>
                            Password with confirmation
                        </template>
                        <template v-slot:value>
                            {{mask(current.password)}}
                        </template>
                        <template v-slot:default>
                            <TextBox v-model="editing.password" name="password" type="password"
                                     errorText="Invalid password"
                                     label="Password" @updated="stateChanged" />
                            <TextBox v-model="editing.confirmPassword" name="confirmPassword" type="password"
                                     errorText="Invalid password confirmation"
                                     label="Confirm Password" @updated="stateChanged" />
                        </template>
                    </editor-content>

                    <editor-content @save="saveValues(['address','zipCode','city','country'])" @cancel="cancelValues(['address','zipCode','city','country'])">
                        <template v-slot:title>
                            Address
                        </template>
                        <template v-slot:value>
                            <span v-html="getAddress()"></span>
                        </template>
                        <template v-slot:default>
                            <TextBox v-model="editing.address" name="address" label="Address" @updated="stateChanged" />
                            <TextBox v-model="editing.zipCode" name="zipCode" label="Zip Code" @updated="stateChanged" />
                            <TextBox v-model="editing.city" name="city" label="City" @updated="stateChanged" />
                            <TextBox v-model="editing.country" name="country" label="Country" @updated="stateChanged" />
                        </template>
                    </editor-content>

                    <editor-content :valid="checkValid(['check'])" :lock-valid="true" @save="saveValues(['check'])" @cancel="cancelValues(['check'])">
                        <template v-slot:title>
                            Mandatory switch
                        </template>
                        <template v-slot:value>
                            {{current.check}}
                        </template>
                        <template v-slot:default>
                            <CheckBox v-model="editing.check"
                                      name="check"
                                      label="Test checkbox"
                                      errorText="Must be checked"
                                      helper="This must be selected"
                                      :must-be-state="true"
                                      type="switch"
                                      :falseValue="0"
                                      :trueValue="1"
                                      @updated="stateChanged" />
                        </template>
                    </editor-content>

                    <editor-content :valid="checkValid(['check2'])" @save="saveValues(['check2'])" @cancel="cancelValues(['check2'])">
                        <template v-slot:title>
                            Optional checkbox
                        </template>
                        <template v-slot:value>
                            {{current.check2}}
                        </template>
                        <template v-slot:default>
                            <CheckBox v-model="editing.check2"
                                      name="check2"
                                      label="Test checkbox"
                                      type="checkbox"
                                      @updated="stateChanged" />
                        </template>
                    </editor-content>

                    <editor-content :valid="checkValid(['date'])" @save="saveValues(['date'])" @cancel="cancelValues(['date'])">
                        <template v-slot:title>
                            Time (via DateTime control)
                        </template>
                        <template v-slot:value>
                            {{current.date}}
                        </template>
                        <template v-slot:default>
                            <DateTime v-model="editing.date" name="date" label="Select a date"
                                      type="time" errorText="Please select a date"
                                      @updated="stateChanged" />
                        </template>
                    </editor-content>

                    <editor-content :valid="checkValid(['dropdown'])" @save="saveValues(['dropdown'])" @cancel="cancelValues(['dropdown'])">
                        <template v-slot:title>
                            Dropdown
                        </template>
                        <template v-slot:value>
                            {{current.dropdown}}
                        </template>
                        <template v-slot:default>
                            <DropDownBox v-model="editing.dropdown"
                                         name="dropdown"
                                         errorText="Please select an option from the list"
                                         pattern=".+" :elements="dropdownchoices"
                                         @updated="stateChanged">
                            </DropDownBox>
                        </template>
                    </editor-content>

                    <editor-content :valid="checkValid(['radiolistVal'])" @save="saveValues(['radiolistVal'])" @cancel="cancelValues(['radiolistVal'])">
                        <template v-slot:title>
                            Radio List Buttons
                        </template>
                        <template v-slot:value>
                            {{current.radiolistVal}}
                        </template>
                        <template v-slot:default>
                            <RadioList v-model="editing.radiolistVal"
                                       name="radio"
                                       label="Radiolist test"
                                       :elements="radiolistchoices"
                                       errorText="Please choose one"
                                       pattern=".+"
                                       @updated="stateChanged" />
                        </template>
                    </editor-content>

                    <editor-content :valid="checkValid(['number'])" @save="saveValues(['number'])" @cancel="cancelValues(['number'])">
                        <template v-slot:title>
                            Number
                        </template>
                        <template v-slot:value>
                            {{current.number}}
                        </template>
                        <template v-slot:default>
                            <Number v-model="editing.number" name="number" label="Test number" />
                        </template>
                    </editor-content>

                    <editor-content :valid="true" :lock-valid="true">
                        <template v-slot:title>
                            Just a constant readonly value
                        </template>
                        <template v-slot:value>
                            Something you can't edit
                        </template>
                    </editor-content>
                </div>
            </div>

            <div class="col-xs-12">
                <button type="button" class="btn btn__round--green"
                        :class="{disabled:!isValid}"
                        :disabled="!isValid"
                        style="padding: 10px; color: white; border-radius: 50px"
                        @click="saySomething">
                    Do something
                </button>
            </div>

            <div class="col-xs-12" style="padding-top:30px;padding-bottom:30px">
                <ul style="list-style-type:none;padding-left:0">
                    <li v-for="(fld,idx) in current" :key="fld"><span style="width:100px;display:inline-block">{{idx}}:</span> <strong>{{fld}}</strong></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script setup>
    import TextArea from '../Shared/Form/TextArea.vue';
    import TextBox from '../Shared/Form/TextBox.vue';
    import CheckBox from '../Shared/Form/CheckBox.vue';
    import DropDownBox from '../Shared/Form/DropDownBox.vue';
    import DateTime from '../Shared/Form/DateTime.vue';
    import RadioList from '../Shared/Form/RadioList.vue';
    import Number from '../Shared/Form/Number.vue';
    import EditorContent from '../Shared/EditorContent.vue';

    import { reactive, watch } from 'vue';
    import { useFormValidator } from '../Shared/Form/FormValidator.js';

    const { stateChanged, isValid, invalidFields } = useFormValidator();

    // define the collective model for the entire form
    let current = reactive({
        textarea: '',
        email: '',
        password: '',
        confirmPassword: '',
        text: '',
        check: false,
        check2: false,
        dropdown: '',
        date: '',
        radiolistVal: '',
        number: 0,
        address: '',
        zipCode: '',
        city: '',
        country: '',
    });

    // copy current values into a new structure used for editing mode values
    // NB: only handles flat structures - alternatively use a similar standard/lib clone-function
    let editing = reactive({});
    for (var key in current)
        editing[key] = current[key];


    // used to check if a group of fields are valid or not (to deactivate or activate save-button)
    const checkValid = (fields) => {
        return invalidFields.value.filter((v) => fields.includes(v)).length == 0;
    }


    // save values in an editor
    // in task based scenarios it might be nescessary to define separate save-methods
    // for each editor-section to handle API-calls to server for the specific task
    const saveValues = (fields) => {
        fields.map((fld) => current[fld] = editing[fld]);
    }


    // cancel changes in an editor
    const cancelValues = (fields) => {
        fields.map((fld) => editing[fld] = current[fld]);
    }


    // a way to watch changes to invalid fields...
    watch(() => invalidFields.value, (nv) => {
        console.log('[TFE] invalid fields', nv);
    });


    // Setup data for form fields which needs supporting structures...
    //
    let dropdownchoices = [
        { key: '', value: 'Choose', selected: true },
        { key: '1', value: 'First one' },
        { key: '2', value: 'Second one' },
        { key: '3', value: 'Third one' },
    ];

    let radiolistchoices = [
        { value: '1', label: 'First one' },
        { value: '2', label: 'Second one' },
        { value: '3', label: 'Third one' },
        { value: '4', label: 'Fourth one' },
    ];

    // helper methods to format data or execute some action
    const getAddress = () => {
        return `${current.address}<br/>${current.zipCode} ${current.city}<br/>${current.country}`;
    }

    const mask = (val) => val.replace(/./gi, '*');

    const saySomething = () => alert('hey');
</script>