<template>
    <bar title="Create Clinic" @close="backToDashboard" />
    <div class="container">
        <div class="row">
            <div class="col-xs-12">
                <div>
                    <form>
                        <organization-search v-model="m.organizationId"
                                             name="organizationId"
                                             label="Organization"
                                             errorText="Organization must be selected"
                                             helper="Search for an organization by entering part of its name and then select the desired one from the list of results"
                                             placeholder="Enter the organization name..."
                                             @updated="stateChanged" />

                        <text-box v-model="m.name"
                                  name="name"
                                  type="text"
                                  label="Name"
                                  @updated="stateChanged" />
                        <text-area v-model="m.address"
                                   name="address"
                                   label="Address"
                                   @updated="stateChanged" />
                        <text-box v-model="m.zipCode"
                                  name="zipCode"
                                  label="Zip Code"
                                  @updated="stateChanged" />
                        <text-box v-model="m.city"
                                  name="city"
                                  label="City"
                                  @updated="stateChanged" />
                        <text-box v-model="m.state"
                                  name="state"
                                  label="State" />
                        <text-box v-model="m.country"
                                  name="country"
                                  label="Country"
                                  @updated="stateChanged" />


                        <GMapMap :center="m.center"
                                 :zoom="10"
                                 ref="gMapRef"
                                 map-type-id="terrain">
                            <GMapCluster>
                                <GMapMarker :key="index"
                                            v-for="(mrk,index) in m.markers"
                                            :position="mrk.position"
                                            :clickable="true"
                                            :draggable="false"
                                            @click="openMarker(mrk.id)">

                                    <GMapInfoWindow :closeclick="true"
                                                    @closeclick="openMarker(null)"
                                                    :opened="m.openedMarkerID === mrk.id">
                                        <div>I am in info window {{ mrk.id }} </div>
                                    </GMapInfoWindow>

                                </GMapMarker>
                            </GMapCluster>
                        </GMapMap>

                        <button type="button" class="btn btn__text btn__text--green" @click="doLocate">SEARCH</button>


                        <text-box v-model="m.email"
                                  name="email"
                                  type="email"
                                  label="E-mail"
                                  @updated="stateChanged" />
                        <text-box v-model="m.phone"
                                  name="phone"
                                  type="text"
                                  label="Phone"
                                  @updated="stateChanged" />

                        <input type="hidden" v-model="m.latitude" />
                        <input type="hidden" v-model="m.longitude" />

                        <button type="button"
                                class="btn btn__text btn__text--large btn__text--green btn__text--short right"
                                :class="{ locked : !isValid }"
                                :disabled="!isValid"
                                @click="createClinic">
                            Create
                        </button>
                    </form>
                </div>
                <div v-show="status">{{status}}</div>
            </div>
        </div>
    </div>
</template>

<script>
    import { computed, ref, reactive } from 'vue';
    import { useRouter } from 'vue-router';
    import { useFormValidator } from '@/Components/Shared/Form/FormValidator';
    import TextBox from '@/Components/Shared/Form/TextBox';
    import TextArea from '@/Components/Shared/Form/TextArea';
    import OrganizationSearch from '@/Components/Shared/Form/OrganizationSearch';
    import { useClinicService } from '@/Components/Organizations/Clinic/ClinicService.js';

    export default {
        name: 'CreateClinic',
        components: {
            TextBox,
            TextArea,
            OrganizationSearch,
        },
        setup() {
            const service = useClinicService();
            const router = useRouter();
            const gMapRef = ref();
            const google = computed(() => window.google);
            let { stateChanged, isValid } = useFormValidator();
            let m = reactive({
                name: '',
                address: '',
                zipCode: '',
                city: '',
                state: '',
                country: '',
                latitude: '',
                longitude: '',
                email: '',
                phone: '',
                organizationId: '',
                center: { lat: 55, lng: 11 },
                openedMarkerID: null,
                markers: []
            });
            let status = ref('');

            const createClinic = async () => {
                try {
                    await service.createClinic(
                        m.name,
                        m.address,
                        m.zipCode,
                        m.city,
                        m.state,
                        m.country,
                        m.latitude,
                        m.longitude,
                        m.email,
                        m.phone,
                        m.organizationId);

                    status.value = "Clinic created.";

                    m.name = '';
                    m.address = '';
                    m.zipCode = '';
                    m.city = '';
                    m.state = '';
                    m.country = '';
                    m.latitude = '';
                    m.longitude = '';
                    m.email = '';
                    m.phone = '';
                    m.organizationId = '';
                }
                catch (e) {
                    console.error(e.message || e);
                }
            }

            const doLocate = () => {
                
                gMapRef.value.$mapPromise.then(() => {

                    const geocoder = new google.value.maps.Geocoder();
                    const address = m.address + " " + m.zipCode + " " + m.city + " " + m.state + " " + m.country;

                    geocoder.geocode({ 'address': address }, function (results, status) {
                        if (status == google.value.maps.GeocoderStatus.OK) {
                            m.latitude = results[0].geometry.location.lat();
                            m.longitude = results[0].geometry.location.lng();
                            
                            m.markers = [];
                            m.markers.push({ id: 1, position: { lat: m.latitude, lng: m.longitude } });
                            
                            gMapRef.value.panTo(new google.value.maps.LatLng(m.latitude, m.longitude));
                        }
                    });
                })
                
            }

            const openMarker = (id) => {            
                console.log(id)
                m.openedMarkerID = id;
            }
             
            const backToDashboard = () => {
                router.push({ name: 'admin' });
            }

            return {
                m,
                doLocate,
                openMarker,
                gMapRef,
                status,
                stateChanged,
                isValid,
                createClinic,
                backToDashboard,
            };
        }
    }
</script>