<template>
    <button type="button" class="btn btn__text--green-border btn__text--large" @click="calcDiff">Diff</button>
    <textarea v-model="eng" style="height:33%;"></textarea>
    <textarea v-model="other" style="height:33%;"></textarea>
    <textarea v-model="diff" style="height:33%;"></textarea>
</template>

<script setup>
    import { ref/*, onMounted*/ } from 'vue';
    //import { useI18n } from 'vue-i18n';

    let eng = ref(JSON.stringify({
  "en": {
        "general": {
            "goBackButton": "GO BACK",
            "back": "Back",
            "search": "Search",
            "save": "Save",
            "ok": "OK",
            "cancel": "Cancel",
            "create": "Create",
            "delete": "Delete",
            "close": "Close",
            "yes": "Yes",
            "no": "No",
            "accept": "Accept",
            "reject": "Reject",
            "view": "View",
            "connect": "Connect",
            "confirm": "Confirm",
            "add": "Add",
            "remove": "Remove",
            "information": "Information",
            "error": "Error",
            "warning": "Warning",
            "help": "Help",
            "send": "Send",
            "update": "Update",
            "record": "Record",
            "play": "Play",
            "start": "Start",
            "stop": "Stop",
            "pause": "Pause",
            "audio": "Audio",
            "reset": "Reset"
        }
    }
    }));
    let other = ref(JSON.stringify({
        "sw": {
        }
    }));
    let diff = ref('');

    //onMounted(() => {
    //})

    const findDiff = (e, o, res) => {
        for (var key in e) {
            //console.log(`searching for ${key} in uk-translation`)
            if (!o || !o[key]) {
                //console.log(`${key} not found in uk-translation`)
                if (typeof e[key] == 'string')
                    res[key] = e[key];
                else {
                    console.log(`${key} is of type ${typeof e[key]}. traversing non-existing object...`)
                    let tmp = findDiff(e[key], null, {});
                    if (tmp && Object.keys(tmp).length) {
                        console.log(`${key} contains non-existing properties...`, tmp)
                        res[key] = tmp;
                    }
                }
            }
            else {
                //console.log(`${key} exists in uk-translation`)
                if (typeof e[key] != 'string') {
                    let tmp = findDiff(e[key], o[key], {});
                    console.log('tmp: ',tmp, tmp.length)
                    if (tmp && Object.keys(tmp).length) {
                        console.log(`${key} contains non-existing properties...`, tmp)
                        res[key] = tmp;
                    }
                }
            }
        }
        return res;
    }

    const calcDiff = () => {
        let en = JSON.parse(eng.value).en;
        let ot = JSON.parse(other.value).sw;
        console.log('structure', en, ot);
        let d = findDiff(en, ot, {});
        diff.value = JSON.stringify(d);
    }

</script>