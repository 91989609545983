<template>
    <div v-if="isClinician" class="overlay overlay--patient" id="listOtherMeasurements">
        <bar :title="$t('createMeasurementOm.title')" @close="closeDialog" />
        <div class="container" style="margin-bottom:20px">
            <div class="row">
                <div class="col-xs-12">
                    <h4>Select a measurement type from the list below to add a new one.</h4>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row list">
                <template v-for="def in measurementDefinitions" :key="def.id">
                    <div class="col-xs-12 list__clickable list__clickable--right" @click="createMeasurement(def)">
                        <div class="title">
                            <span>
                                {{$t(def.titleKey)}}
                            </span>
                            <div class="svg-container">
                                <svg-icon icon-name="chevron" />
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="measurementDefinitions.length == 0">
                    <div class="col-xs-12 center-text">
                        <p>There are currently no measurements registered</p>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import { useStore } from 'vuex';
    import { useRouter } from 'vue-router';
    import { useI18n } from 'vue-i18n';
    import {
        BLOODTYPEKEY,
        BLOODTYPEKEY2,
        BLOODPRESSUREKEY,
        BLOODSUGARKEY,
        TEMPERATUREKEY,
        TEMPERATUREKEY2,
        WEIGHTKEY,
        WEIGHTKEY2,
        HEIGHTKEY,
        HEIGHTKEY2
    } from '@/Components/HealthData/Measurements/MeasurementHelperService.js';

    const store = useStore();
    const isClinician = computed(() => store.getters.isClinician);
    const router = useRouter();
    const { t } = useI18n();

    // exclude these measurements, as they already appear in the summary overview
    const excludedMeasurements = [
        HEIGHTKEY2,
        WEIGHTKEY2,
        BLOODTYPEKEY2,
        BLOODPRESSUREKEY,
        BLOODSUGARKEY,
        TEMPERATUREKEY2,
        'bloodSugar',
        BLOODTYPEKEY,
        TEMPERATUREKEY,
        WEIGHTKEY,
        HEIGHTKEY
    ];

    // get all measurements which are not excluded (because they appear in the summary)
    const measurementDefinitions = computed(() => {
        let fmd = store.getters.getMeasurementDefinitions.filter((d) => !excludedMeasurements.includes(d.valueDefinition.codingCode));
        return fmd.sort((a, b) => t(a.titleKey + '') < t(b.titleKey) ? -1 : 1);
    });

    const closeDialog = () => router.push({ name: 'measurements' });

    const createMeasurement = (measurementDefinition) => {
        console.log("[ListOtherMeasurement.createMeasurement]", measurementDefinition);
        router.push({
            name: 'create measurement component',
            params: {
                measurementDefinitionId: measurementDefinition.valueDefinition.codingCode
            }
        });
    };
</script>
