import { createRouter, createWebHistory } from 'vue-router'

// Welcome
import welcome from '@/Components/Welcome/Welcome.vue'
import logon from '@/Components/Users/User/LogonUsername.vue'
import logonPin from '@/Components/Users/User/LogonPIN.vue'
import logonPassword from '@/Components/Users/User/Logon.vue'
import offlineNoLogon from '@/Components/Users/User/OfflineNoLogon.vue'
import register from '@/Components/Patients/Patient/RegisterPatient.vue'
import resetRequest from '@/Components/Users/User/RequestResetPassword.vue'
import resetPassword from '@/Components/Users/User/ResetPassword.vue'
import verify from '@/Components/Welcome/Verify.vue'
import helplinks from '@/Components/Welcome/HelpLinks.vue'
import verifyEmailChange from '@/Components/Welcome/VerifyEmailChange.vue'

// Clinician Dashboard
import clinician from '@/Components/Organizations/Clinician/Clinician'
import clinicianData from '@/Components/Organizations/Clinician/ClinicianData'
import mypatients from '@/Components/Organizations/Clinician/MyPatients'
import qrPatient from '@/Components/Organizations/Clinician/QrPatient'
import findpatient from '@/Components/Organizations/Clinician/FindPatients'
import clinicianBookingRequests from '@/Components/Organizations/Clinician/BookingRequests'
import clinicianBookingRequest from '@/Components/Organizations/Clinician/BookingRequest'
import clinicianSchedule from '@/Components/Organizations/Clinician/MySchedule'
import pushDashboard from '@/Components/Organizations/Push/Dashboard'
import pushDonorRequest from '@/Components/Organizations/Push/DonorRequest'
import pushGeneralMessage from '@/Components/Organizations/Push/GeneralMessage'
import clinicianChangeEmail from '@/Components/Organizations/Clinician/ClinicianChangeEmail'
import clinicianChangePhone from '@/Components/Organizations/Clinician/ClinicianChangePhone'

// Record
import record from '@/Components/Records/Record/Record.vue'

// Diagnosis 
import diagnosis from '@/Components/HealthData/Diagnosis/Diagnosis.vue'
import createDiagnosis from '@/Components/HealthData/Diagnosis/CreateDiagnosis.vue'
import editDiagnosis from '@/Components/HealthData/Diagnosis/EditDiagnosis.vue';

// Medication
import medication from '@/Components/Medications/Medication/Medication.vue'
import editMedication from '@/Components/Medications/Medication/EditMedication.vue'
import createMedication from '@/Components/Medications/Medication/CreateMedication.vue'
import cave from '@/Components/Medications/Cave/EditCave.vue';

// Immunization
import immunization from '@/Components/HealthData/Immunization/Immunization.vue'
import editImmunization from '@/Components/HealthData/Immunization/EditImmunization.vue'
import createImmunization from '@/Components/HealthData/Immunization/CreateImmunization.vue'

// Measurement
import measurements from '@/Components/HealthData/Measurements/Measurements.vue'
import editMeasurement from '@/Components/HealthData/Measurements/EditMeasurement.vue'
import createMeasurement from '@/Components/HealthData/Measurements/CreateMeasurement.vue'
import listOtherMeasurements from '@/Components/HealthData/Measurements/ListOtherMeasurements.vue'
import genericCreate from '@/Components/HealthData/Measurements/Types/GenericCreate.vue'
import genericEdit from '@/Components/HealthData/Measurements/Types/GenericEdit.vue'

// Patient
import patient from '@/Components/Patients/Patient/Patient.vue'
import myClinicians from '@/Components/Patients/Patient/MyClinicians.vue'
import listClinicianRequests from '@/Components/Patients/Patient/ListClinicianRequests.vue'
import findClinic from '@/Components/Patients/Patient/FindClinic.vue'
import myrelations from '@/Components/Patients/Patient/MyRelations.vue'
import myQr from '@/Components/Patients/Patient/MyQr.vue'
import patientData from '@/Components/Patients/Patient/PatientData.vue'
import patientAppointments from '@/Components/Patients/Patient/PatientAppointments.vue'
import patientChangeEmail from '@/Components/Patients/Patient/PatientChangeEmail'
import patientChangePhone from '@/Components/Patients/Patient/PatientChangePhone'


import patientBilling from '@/Components/Patients/Patient/PatientBilling.vue'
import createInvoice from '@/Components/Consultations/Invoice/CreateInvoice.vue'
import viewInvoice from '@/Components/Consultations/Invoice/ViewInvoice.vue'
import auditLogEntries from '@/Components/Patients/Patient/PatientAuditLog.vue'
import addRelatedPerson from '@/Components/Patients/Patient/AddRelatedPerson.vue'
import editRelatedPerson from '@/Components/Patients/Patient/EditRelatedPerson.vue'
import addRelatedPersonPatientData from '@/Components/Patients/Patient/AddRelatedPersonPatientData.vue'


import patientEpisodeOfCare from '@/Components/HealthData/EpisodeOfCare/EpisodeOfCare.vue'
import patientCreatePregnancyEpisodeOfCare from '@/Components/HealthData/EpisodeOfCare/CreatePregnancyEpisodeOfCare.vue'
import patientEditPregnancyEpisodeOfCare from '@/Components/HealthData/EpisodeOfCare/EditPregnancyEpisodeOfCare.vue'

import patientConsents from '@/Components/Patients/Patient/PatientConsent.vue'

// Messaging
import clinicConversations from '@/Components/Organizations/Clinician/ClinicianClinicConversations.vue'
import clinicConversation from '@/Components/Organizations/Clinician/ClinicianClinicConversation.vue'
import clinicConversationMessage from '@/Components/Organizations/Clinician/ClinicianClinicConversationMessage.vue'
import clinicConversationMessageNew from '@/Components/Organizations/Clinician/ClinicianClinicConversationMessageNew.vue'
import patientConversations from '@/Components/Patients/Patient/PatientConversations.vue'
import patientConversation from '@/Components/Patients/Patient/PatientConversation.vue'
import patientConversationMessage from '@/Components/Patients/Patient/PatientConversationMessage.vue'
import patientConversationMessageNew from '@/Components/Patients/Patient/PatientConversationMessageNew.vue'


// Note
import note from '@/Components/HealthData/ConsultationNote/ConsultationNote.vue';
//import createConsultationNote from '@/Components/HealthData/ConsultationNote/CreateConsultationNote.vue';
import editConsultationNote from '@/Components/HealthData/ConsultationNote/EditConsultationNote.vue';

// Coded concept
//import codedConceptSearch from '@/Components/Terminology/Shared/CodedConceptSearch.vue';

// Clinic
import clinicBooking from '@/Components/Consultations/BookingRequest/RequestBooking'

// Global
import settings from '@/Components/Shared/Settings.vue'
import dashboard from '@/Components/Shared/Dashboard.vue'

//import notImplemented from '@/Components/Shared/NotImplemented.vue'
import notFound from '@/Components/Shared/NotFound.vue'

// Test
import testForm from '@/Components/TestForm/TestForm.vue'
import testEditForm from '@/Components/TestForm/TestEditForm.vue'
import langFlattener from '@/Components/TestForm/langFlattener.vue'
import langDiffer from '@/Components/TestForm/langDiff.vue'

// Admin
import adminMain from '@/Components/TestForm/Admin'
import adminDashboard from '@/Components/TestForm/AdminDashboard'
import registerClinician from '@/Components/TestForm/RegisterClinician'
import createClinic from '@/Components/TestForm/CreateClinic'
import createOrganization from '@/Components/TestForm/CreateOrganization'
import importMeasurementDefinitions from '@/Components/TestForm/ImportMeasurementDefinitions'

import AdminLocalComboMedicationVue from '../Components/TestForm/AdminLocalComboMedication.vue'
import AdminLocalComboMedicationCreateVue from '../Components/TestForm/AdminLocalComboMedicationCreate.vue'


export const routes = [
    {
        name: 'welcome',
        path: '/welcome',
        alias: ['', '/'],
        component: welcome,
        children: [
            {
                name: 'login',
                path: 'login',
                component: logon,
            },
            {
                name: 'logon pin',
                path: 'logonpin',
                component: logonPin,
                props: (route) => ({
                    showPin: true,
                    pinType: route.params.pinType,
                    action: route.params.action,
                    userId: route.params.userId,
                    dlgTs: new Date().getTime(),
                }),
            },
            {
                name: 'logon password',
                path: 'logonpassword',
                component: logonPassword,
                props: (route) => ({
                    username: route.params.username
                }),
            },
            {
                name: 'register',
                path: 'register',
                component: register,
            },
            {
                name: 'reset',
                path: 'reset',
                component: resetRequest,
            },
            {
                name: 'resetpassword',
                path: 'resetpassword/:id',
                component: resetPassword,
                props: (route) => ({
                    id: route.params.id,
                    token: route.query.token,
                }),
            },
            {
                name: 'verify',
                path: 'verify',
                component: verify,
                props: (route) => ({
                    id: route.query.id,
                    token: route.query.token,
                    email: route.query.email,
                    phone: route.query.phone,
                }),
            },
            {
                name: 'verify email change',
                path: 'verifyemailchange',
                component: verifyEmailChange,
                props: (route) => ({
                    id: route.query.id,
                    token: route.query.token
                })
            },
            {
                name: 'helplinks',
                path: 'helplinks',
                component: helplinks,
            },
            {
                name: 'offline cant logon',
                path: 'offline',
                component: offlineNoLogon
            },
        ]
    },
    {
        name: 'dashboard',
        path: '/dashboard',
        component: dashboard,
        children: [
            // Clinician
            {
                name: 'mypatients',
                path: 'mypatients',
                component: mypatients,
            },
            {
                name: 'qr',
                path: 'qr',
                component: qrPatient,
            },
            {
                name: 'findpatient',
                path: 'findpatient',
                component: findpatient,
                children: [
                    {
                        name: 'qrfindpatient',
                        path: ':qr',
                        component: findpatient,
                    },
                ]
            },
            {
                name: 'clinician settings',
                path: 'cliniciansettings',
                component: settings,
            },
            {
                name: 'clinician booking requests',
                path: 'bookingrequests',
                component: clinicianBookingRequests,
            },
            {
                name: 'clinician booking request',
                path: 'bookingrequest',
                component: clinicianBookingRequest,
                props: (route) => ({ bookingRequestId: route.query.bookingRequestId }),
            },
            {
                name: 'myschedule',
                path: 'myschedule',
                component: clinicianSchedule,
            },
            {
                name: 'push dashboard',
                path: 'push',
                component: pushDashboard,
                children: [
                    {
                        name: 'push donor request',
                        path: 'donorrequest',
                        component: pushDonorRequest
                    },
                    {
                        name: 'push general message',
                        path: 'generalmessage',
                        component: pushGeneralMessage
                    },
                ]
            },
            // Patient
            {
                name: 'myqr',
                path: 'myqr',
                component: myQr,
            },
            {
                name: 'myrelations',
                path: 'myrelations',
                component: myrelations,
                children: [
                    {
                        name: 'add related person',
                        path: 'addrelatedperson',
                        component: addRelatedPerson,
                    },
                    {
                        name: 'editrelatedperson',
                        path: ':id',
                        component: editRelatedPerson,
                        props: true,
                    },
                    {
                        name: 'addrelatedpersonpatientdata',
                        path: 'addrelatedpersonpatientdata',
                        component: addRelatedPersonPatientData,
                        props: (route) => ({ relatedPersonId: route.query.relatedPersonId }),
                    },
                ]
            },
            {
                name: 'findclinic',
                path: 'findclinic',
                component: findClinic,
            },
            {
                name: 'myclinicians',
                path: 'myclinicians',
                component: myClinicians,
            },
            {
                name: 'listClinicianRequests',
                path: 'clinicianRequests',
                component: listClinicianRequests,
            },
            {
                name: 'settings',
                path: 'settings',
                component: settings,
            },
            {
                name: 'clinic booking',
                path: 'booking/:clinicId',
                component: clinicBooking,
                props: true
            },
            {
                name: 'patient telemed',
                path: 'telemed',
                redirect: { name: 'dashboard' }
            }
        ]
    },
    {
        name: 'clinician',
        path: '/clinician',
        component: clinician,
        children: [
            {
                name: 'clinician data',
                path: '',
                component: clinicianData,
                children: [
                    {
                        name: 'clinician change email',
                        path: 'changeemail',
                        component: clinicianChangeEmail
                    },
                    {
                        name: 'clinician change phone',
                        path: 'changephone',
                        component: clinicianChangePhone
                    },
                ]
            },
            {
                name: 'clinic conversations',
                path: 'conversations',
                component: clinicConversations,
                children: [
                    {
                        name: 'clinic conversation',
                        path: ':patientId',
                        component: clinicConversation,
                        props: (route) => ({
                            patientId: route.params.patientId,
                        }),
                        children: [
                            {
                                name: 'clinic conversation message',
                                path: '/clinician/conversations/:messageId/message',
                                component: clinicConversationMessage,
                                props: true,
                            },
                            {
                                name: 'new clinic conversation message',
                                path: '/clinician/conversations/:patientId/create',
                                component: clinicConversationMessageNew,
                                props: (route) => ({
                                    patientId: route.params.patientId,
                                }),
                            },
                        ]
                    },
                ]
            },
        ],
    },
    {
        name: 'patient',
        path: '/patient',
        component: patient,
        children: [
            {
                name: 'patient settings',
                path: 'settings',
                component: settings,
            },
            {
                name: 'record',
                path: 'record',
                component: record,
            },
            {
                name: 'diagnosis',
                path: 'diagnosis',
                component: diagnosis,
                children: [
                    {
                        name: 'create diagnosis',
                        path: 'create',
                        component: createDiagnosis,
                        props: true,
                    },
                    {
                        name: 'edit diagnosis',
                        path: ':id',
                        component: editDiagnosis,
                        props: true,
                    }
                ]
            },
            {
                name: 'medication',
                path: 'medication',
                component: medication,
                children: [
                    {
                        name: 'create medication',
                        path: 'create',
                        component: createMedication,
                        props: true,
                    },
                    {
                        name: 'edit medication',
                        path: ':id',
                        component: editMedication,
                        props: true,
                    }
                ]
            },
            {
                name: 'immunization',
                path: 'immunization',
                component: immunization,
                children: [
                    {
                        name: 'create immunization',
                        path: 'create',
                        component: createImmunization,
                        props: true,
                    },
                    {
                        name: 'edit immunization',
                        path: ':id',
                        component: editImmunization,
                        props: true,
                    }
                ]
            },
            {
                name: 'measurements',
                path: 'measurements',
                component: measurements,
                children: [
                    {
                        name: 'list other measurements',
                        path: 'othermeasurements',
                        component: listOtherMeasurements,
                    },
                    {
                        name: 'create measurement',
                        path: 'create',
                        component: createMeasurement,
                        children: [
                            {
                                name: 'create measurement component',
                                path: ':measurementDefinitionId',
                                component: genericCreate,
                                props: true,
                            },
                        ],
                    },
                    {
                        name: 'edit measurement',
                        path: 'view',
                        component: editMeasurement,
                        children: [
                            {
                                name: 'edit measurement component',
                                path: ':id',
                                component: genericEdit,
                                props: true,
                            }
                        ],
                    }
                ]
            },
            {
                name: 'cave',
                path: 'cave',
                component: cave,
            },
            {
                name: 'note',
                path: 'note',
                component: note,
                props: true,
                children: [
                    {
                        name: 'edit note',
                        path: ':id',
                        component: editConsultationNote,
                        props: true,
                    }
                ]
            },
            {
                name: 'episodeofcare',
                path: 'eoc',
                component: patientEpisodeOfCare,
                children: [
                    {
                        name: 'createpregnancyepisodeofcare',
                        path: 'pregnancy',
                        component: patientCreatePregnancyEpisodeOfCare,
                        children: [
                            {
                                name: 'eoccreate createmeasurement',
                                path: 'createmeasurement',
                                component: createMeasurement,
                                props: route => ({ returnToName: route.query.returnToName }),
                                children: [
                                    {
                                        name: 'eoccreate create measurement',
                                        path: ':measurementDefinitionId',
                                        component: genericCreate,
                                        props: true,
                                    },
                                ]
                            }
                        ]
                    },
                    {
                        name: 'editpregnancyepisodeofcare',
                        path: 'pregnancy/:id',
                        component: patientEditPregnancyEpisodeOfCare,
                        props: true,
                        children: [
                            {
                                name: 'eocedit createmeasurement',
                                path: 'createmeasurement2',
                                component: createMeasurement,
                                props: route => ({ returnToName: route.query.returnToName }),
                                children: [
                                    {
                                        name: 'eocedit create measurement',
                                        path: ':measurementDefinitionId',
                                        component: genericCreate,
                                        props: true,
                                    },
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                name: 'data',
                path: 'data',
                component: patientData,
                children: [
                    {
                        name: 'patient change email',
                        path: 'changeemail',
                        component: patientChangeEmail
                    },
                    {
                        name: 'patient change phone',
                        path: 'changephone',
                        component: patientChangePhone
                    },
                ]
            },
            {
                name: 'appointments',
                path: 'appointments',
                component: patientAppointments,
                props: (route) => ({ tabNumber: Number(route.query.tabNumber || 0) }),
            },
            {
                name: 'patientbilling',
                path: 'billing',
                component: patientBilling,
                children: [
                    {
                        name: 'createinvoice',
                        path: 'create',
                        component: createInvoice,
                    },
                    {
                        name: 'viewinvoice',
                        path: 'view',
                        component: viewInvoice,
                        props: (route) => ({ invoiceId: route.query.invoiceId }),
                    },
                ]
            },
            {
                name: 'patient conversations',
                path: 'conversations',
                component: patientConversations,
                children: [
                    {
                        name: 'patient conversation',
                        path: ':clinicId',
                        component: patientConversation,
                        props: (route) => ({
                            clinicId: route.params.clinicId,
                        }),
                        children: [
                            {
                                name: 'patient conversation message',
                                path: '/patient/conversations/:messageId/message',
                                component: patientConversationMessage,
                                props: true
                            },
                            {
                                name: 'new patient conversation message',
                                path: '/patient/conversations/:clinicId/create',
                                component: patientConversationMessageNew,
                                props: (route) => ({
                                    clinicId: route.params.clinicId,
                                }),
                            },
                        ]
                    },
                ],
            },
            {
                name: 'patient consents',
                path: 'consents',
                component: patientConsents,
            },
            {
                name: 'auditLogEntries',
                path: 'auditlog',
                component: auditLogEntries,
            },
        ]
    },
    {
        path: '/admin',
        name: 'admin',
        redirect: { name: 'admin dashboard' },
        component: adminMain,
        children: [
            {
                path: '',
                name: 'admin dashboard',
                component: adminDashboard,
            },
            {
                path: 'registerclinician',
                name: 'register clinician',
                component: registerClinician,
            },
            {
                path: 'createclinic',
                name: 'create clinic',
                component: createClinic,
            },
            {
                path: 'createorganization',
                name: 'create organization',
                component: createOrganization,
            },
            {
                path: 'importmeasurementdefinitions',
                name: 'import measurement definitions',
                component: importMeasurementDefinitions,
            },
            {
                path: 'localcombomeds',
                name: 'list local combo medications',
                component: AdminLocalComboMedicationVue,
                children: [
                    {
                        path: 'create',
                        name: 'create local combo medication',
                        component: AdminLocalComboMedicationCreateVue,
                    },
                    {
                        path: 'edit/:codingCode',
                        name: 'edit local combo medication',
                        component: AdminLocalComboMedicationCreateVue,
                        props: true
                    },
                ]
            },
        ]
    },
    {
        path: '/testform',
        name: 'testForm',
        component: testForm,
    },
    {
        path: '/testeditform',
        name: 'testEditForm',
        component: testEditForm,
    },
    {
        path: '/langflat',
        name: 'langflat',
        component: langFlattener,
    },
    {
        path: '/langdiff',
        name: 'langdiff',
        component: langDiffer,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'page not found',
        component: notFound,
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});


//Route navigation / Animation
export const fnAfterEach = (to, from) => {
    //if (to.meta.noAnimation) {
    //    to.meta.transitionName = '';
    //    return;
    //}
    const toDepth = to.path.split('/').length
    const fromDepth = from.path.split('/').length
    to.meta.transitionName = toDepth < fromDepth ? 'parrent' : 'child';
    console.log('animating to : ' + to.meta.transitionName);
};

router.afterEach(fnAfterEach);

// Access rerouting
import dispatcher from '@/Connections/Dispatcher.js';
import * as strings from '@/Shared/Strings.js';
import accessTokens from '@/Connections/AccessTokens.js';

export const fnBeforeEach = async (to, from) => {
    console.log(`Routing engaged from ${from.name} (${from.fullPath} -> ${to.fullPath})...`);

    let publicPages = ['welcome', 'register', 'login', 'logon pin', 'logon password', 'offline cant logon', 'reset', 'resetpassword', 'noUser', 'verify', 'helplinks', 'verify email change'];
    let token = await accessTokens.hasVerifiedToken();

    if (!publicPages.includes(to.name) && !token)
        return { name: 'welcome' };

    if (token) {
        if ((to.name == 'welcome' || to.name == 'register') && dispatcher.getFromLocalClear(strings.ONLINESTATUS))
            return { name: 'dashboard' };

        if (to.path.startsWith('/admin')) {
            try {
                let u = dispatcher.getFromLocal(strings.USERSEMAIL);
                if (u.substr(-15) != '@codeoptimus.dk')
                    return { name: 'page not found' };
            }
            catch (e) {
                return from;
            }
        }
    }

    if (publicPages.includes(to.name)) {
        document.body.classList.add('public');
    }
    else {
        document.body.classList.remove('public')
    }

}

router.beforeEach(fnBeforeEach);


export default router;
