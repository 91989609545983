export const PATIENTID = 'PatientId';
export const PATIENTUSERNAME = 'PatientUsername';
export const PATIENTNAME = 'PatientName';
export const PATIENTGIVENNAMES = 'PatientGivenNames';
export const PATIENTFAMILYNAME = 'PatientFamilyName';
export const PATIENTIMAGE = 'PatientImage';
export const PATIENTPHONE = 'PatientPhone';
export const PATIENTEMAIL = 'PatientEmail';
export const PATIENTGENDER = 'PatientGender';
export const PATIENTBIRTHDATE = 'PatientBirthdate';
export const PATIENTCAVE = 'PatientCave';
export const PATIENTRECORD = 'PatientRecord';
export const PATIENTDIAGNOSES = 'PatientDiagnoses';
export const PATIENTMEDICATIONS = 'PatientMedications';
export const PATIENTIMMUNIZATIONS = 'PatientImmunizations';
export const PATIENTMEASUREMENTS = 'PatientMeasurements';
export const PATIENTCONSULTATIONNOTES = 'PatientConsultationNotes';
export const PATIENTBOOKINGS = 'PatientBookings';
export const PATIENTREQUESTS = 'PatientRequests';
export const PATIENTORGANIZATIONS = 'PatientOrganizations';
export const PATIENTRELATEDPERSONS = 'PatientRelatedPersons';
export const PATIENTINVOICES = 'PatientInvoices';
export const PATIENTPATIENTACCESS = 'PatientPatientAccess';
export const PATIENTMESSAGES = 'PatientMessages';
export const PATIENTCONVERSATIONS = 'PatientConversations';
export const PATIENTEPISODESOFCARE = 'PatientEpisodesOfCare';
export const PATIENTCONSENTS = 'PatientConsents';
export const PATIENTPHONECHANGE = 'PatientPhoneChange';
export const PATIENTEMAILCHANGE = 'PatientEmailChange';

export const CLINICIANID = 'ClinicianId';
export const CLINICIANCLINICID = 'ClinicianClinicId';
export const CLINICIANORGANIZATIONID = 'ClinicianOrganizationId';
export const CLINICIANNAME = 'ClinicianName';
export const CLINICIANGIVENNAMES = 'ClinicianGivenNames';
export const CLINICIANFAMILYNAME = 'ClinicianFamilyName';
export const CLINICIANPHONE = 'ClinicianPhone';
export const CLINICIANIMAGE = 'ClinicianImage';
export const CLINICIANEMAIL = 'ClinicianEmail';
export const CLINICIANGENDER = 'ClinicianGender';
export const CLINICIANDESCRIPTION = 'ClinicianDescription';
export const CLINICIANBOOKINGS = 'ClinicianBookings';
export const CLINICIANPATIENTACCESS = 'ClinicianPatientAccess';
export const CLINICIANMESSAGES = 'ClinicianMessages';   //TODO: may be obsolete!
export const CLINICIANCONVERSATIONS = 'ClinicianConversations';
export const CLINICIANCURRENTCONVERSATION = 'ClinicianCurrentConversation';
export const CLINICIANLOCALE = 'ClinicianLocale';
export const CLINICIANPHONECHANGE = 'ClinicianPhoneChange';
export const CLINICIANEMAILCHANGE = 'ClinicianEmailChange';

export const CLINICREQUESTS = 'ClinicRequests';

export const CLIENTID = 'ClientId';

export const USERID = 'UserId';
export const USERIMAGE = 'UserImage';
export const USERLANGUAGE = 'UserLanguage';
export const USERTYPE = 'UserType';
export const USERNAME = 'Username';
export const USERSNAME = 'UsersName';
export const USERSEMAIL = 'UsersEmail';
export const USERSPHONE = 'UsersPhone';
export const USERNOTIFICATIONS = 'UserNotifications';
export const USERNOTIFICATIONSTIMESTAMP = 'UserNotificationsTimestamp';
export const USERPIN = 'UserPIN';

export const USERS = 'Users';

export const PATIENTACCESS = 'PatientAccess';

export const RELATINGPATIENTID = 'RelatingPatientId';

export const REFRESHTOKEN = 'RefreshToken';
export const ACCESSTOKEN = 'AccessToken';

export const ONLINESTATUS = 'OnlineStatus';

export const MEASUREMENTDEFINITIONS = 'MeasurementDefinitions';

export const POPUPTYPE_ALERT = 'alert';
export const POPUPTYPE_CONSENT = 'consent';
export const POPUPTYPE_HELPER = 'helper';
export const POPUPTYPE_INFO = 'info';

export const DEVICE_LANG_COOKIENAME = 'lang';

export const CURRENTLOCALSTORAGEVERSION = 3;