import { createStore } from 'vuex';
import dispatcher from '@/Connections/Dispatcher.js';
import * as strings  from '@/Shared/Strings.js';

const storeOptions = {
    state: {        // equiv to data or reactive object in component
        user: {
            id: null,
            userName: null,
            name: null,
            email: null,
            phone: null,
            image: null,
            language: null,
            type: null,
            notifications: null,
            pin: null,
        },
        patient: {
            id: null,
            username: null,
            name: null,
            givenNames: null,
            familyName: null,
            email: null,
            phone: null,
            image: null,
            birthdate: null,
            gender: null,
            cave: null,
            record: null,
            diagnoses: null,
            medications: null,
            immunizations: null,
            measurements: null,
            consultationNotes: null,
            bookings: null,
            requests: null,
            organizations: null,
            relatedPersons: null,
            invoices: null,
            patientAccess: null,
            conversations: null,
            messages: null,
            episodesOfCare: null,
            consents: null,
            pendingEmailChange: null,
            pendingPhoneChange: null,
        },
        clinician: {
            id: null,
            clinicId: null,
            organizationId: null,
            name: null,
            givenNames: null,
            familyName: null,
            email: null,
            phone: null,
            image: null,
            gender: null,
            description: null,
            bookings: null,
            requests: null,
            patientAccess: null,
            conversations: null,
            currentConversation: null,
            locale: null,
            pendingEmailChange: null,
            pendingPhoneChange: null,
        },
        //patientAccess: null || [],
        relatingPatientId: null,
        isLoading: false,
        notificationIsOpen: false,
        sideNavigationIsOpen: false,
        isOnline: dispatcher.getFromLocalClear(strings.ONLINESTATUS),
        conversationTimestamp: new Date().getTime(),
        measurementDefinitions: null,
    },

    getters: {      // equiv to computed properties
        // as prop: getter = (state[, getters]) => {}
        // as func: getter = (state[, getters]) => (...params) => {}
        // IMPORTANT NOTE: state MUST be passed even if it is not used in the getter, otherwise the getter will not work!
        getIsOnline: (state) => {
            return state.isOnline;
        },
        getIsLoggedOn: (state) => {
            return state.user.id != null
                && (
                    (state.user.type == 'patient' && state.patient.id != null)
                    || (state.user.type == 'clinician' && state.clinician.id != null)
                );
        },
        getUserPIN: (state) => {
            return state.user.pin || '';
        },
        userId: (state) => {
            if (!(state && state.user)) return '';
            return state.user.id || '';
        },
        getUserId: (state) => {
            if (!(state && state.user)) return '';
            return state.user.id || '';
        },
        getUserType: (state) => {
            if(!(state && state.user && state.user.type)) return '';
            return state.user.type.charAt(0).toUpperCase() + state.user.type.substring(1);
        },
        getUserImage: (state) => {
            if (!state.user && !state.user.type) return '';
            if (state.user.type == 'patient' && state.patient) {
                return state.patient.image;
            }
            else if (state.user.type == 'clinician' && state.clinician) {
                return state.clinician.image;
            }
        },
        getUsername: ({ user }) => {
            if (!user) return null;
            return user.userName;
        },
        getUserLanguage: ({ user }) => {
            if (!user || !user.language) return 'en';
            return user.language;
        },
        getUserProfileId: (state) => {
            if (!state.user && !state.user.type) return '';
            if (state.user.type == 'patient' && state.patient) {
                return state.patient.id;
            }
            else if (state.user.type == 'clinician' && state.clinician) {
                return state.clinician.id;
            }
            else
                return '';
        },
        getUsersProfileName: (state) => {
            if (!state.user && !state.user.type) return '';
            if (state.user.type == 'patient' && state.patient) {
                return state.patient.name;
            }
            else if (state.user.type == 'clinician' && state.clinician) {
                return state.clinician.name;
            }
        },

        patientId: (state) => {
            if (!(state && state.patient)) return null;
            return state.patient.id || null;
        },
        getPatientId: (state) => {
            if (!(state && state.patient)) return null;
            return state.patient.id || null;
        },
        isPatient: (state) => {
            if (!(state && state.user && state.user.type)) return false;
            return state.user.type == 'patient';
        },
        getPatientUsername: (state) => {
            return state.patient.username || '';
        },

        isRelatedPatient: (state) => {
            if (!(state && state.relatingPatientId)) return false;
            return state.relatingPatientId != null;
        },
        getRelatingPatientId: (state) => {
            if (!(state && state.relatingPatientId)) return null;
            return state.relatingPatientId;
        },

        clinicianId: (state) => {
            if (!(state && state.clinician)) return '';
            return state.clinician.id || '';
        },
        isClinician: (state) => {
            if (!(state && state.user && state.user.type)) return false;
            return state.user.type == 'clinician';
        },
        getClinician: (state) => {
            if (!(state && state.clinician)) return null;
            return state.clinician;
        },
        getClinicianId: ({ clinician }) => {
            if (!clinician) return '';
            return clinician.id || '';
        },
        getClinicianName:(state) => {
            if (!(state && state.clinician)) return '';
            return state.clinician.name;
        },
        getClinicianClinicId: ({ clinician }) => {
            if (!clinician) return '';
            return clinician.clinicId || '';
        },
        getClinicianOrganizationId: ({ clinician }) => {
            if (!clinician) return '';
            return clinician.organizationId || '';
        },
        getClinicianLocale: ({ clinician }) => {
            return clinician?.locale || 'en-US';
        },
        getClinicianBookings: (state) => {
            if (!(state && state.clinician && state.clinician.bookings)) return [];
            return state.clinician.bookings.filter((b) => b.bookingState != 0);
        },
        getClinicBookingRequests: (state) => {
            if (!(state && state.clinician && state.clinician.requests)) return [];
            return state.clinician.requests;
        },

        getPatient: (state) => {
            if (!(state && state.patient)) return null;
            return state.patient;
        },
        getOfflinePatient: (state) => (id) => {
            return dispatcher.getFromLocal(state.user.id + '_p_' + id);
        },

        getPatientRelatedPersons: (state) => {
            if (!(state && state.patient && state.patient.relatedPersons && Array.isArray(state.patient.relatedPersons))) return [];
            return state.patient.relatedPersons;
        },
        getPatientRelatedPerson: (state, getters) => (id) => {
            let relatedPerson = getters.getPatientRelatedPersons.filter((rp) => rp.relatedPersonId == id);
            if (relatedPerson.length == 0)
                return null;
            return relatedPerson[0];
        },

        getPatientCave: (state) => {
            if (!(state && state.patient && state.patient.cave)) return null;
            return state.patient.cave;
        },
        getPatientCaveId: (state, getters) => {
            let cave = getters.getPatientCave;
            if (!cave) return '';
            return cave.id;
        },
        getPatientCaveText: (state, getters) => {
            let cave = getters.getPatientCave;
            if (!cave) return '';
            return cave.text;
        },

        getPatientName: ({ patient }) => {
            if (!patient) return '';
            return patient.name;
        },
        getPatientFamilyName: ({ patient }) => {
            if (!patient) return '';
            return patient.familyName;
        },
        getPatientGivenNames: ({ patient }) => {
            if (!patient) return '';
            return patient.givenNames;
        },
        getPatientBookings: (state) => {
            if (!(state && state.patient && state.patient.bookings)) return [];
            return state.patient.bookings;
        },
        getPatientBookingRequests: (state) => {
            if (!(state && state.patient && state.patient.requests)) return [];
            return state.patient.requests;
        },
        getPatientInvoices: (state) => {
            if (!(state && state.patient && state.patient.invoices)) return [];
            return state.patient.invoices;
        },
        getPatientInvoice: (state, getters) => (id) => {
            let lst = getters.getPatientInvoices;
            let foundInvoice = lst.filter((i) => i.invoiceId == id);
            if (foundInvoice.length == 1)
                return foundInvoice[0];
            return null;
        },
        getUserNotifications: (state) => {
            if (!(state && state.user && state.user.notifications && Array.isArray(state.user.notifications))) return [];
            return state.user.notifications || [];
        },

        getPatientMeasurements: (state) => {
            if (!(state && state.patient && state.patient.measurements && Array.isArray(state.patient.measurements))) return [];
            return state.patient.measurements;
        },
        getNewestPatientMeasurementByCode: (state, getters) => (codes) => {
            return getters.getPatientMeasurements
                .filter((v) => codes.filter((cc) => v.measurementValue.value.definition.codingCode == cc.code && (v.measurementValue.value.definition.codingSystem == cc.system || "http://snomed.info/sct")).length > 0)
                .reduce((p, c) => !p || new Date(p.date) < new Date(c.date) ? c : p, null);
        },

        getCliniciansPatientAccess: (state) => {
            if (!(state && state.clinician && state.clinician.patientAccess)) return [];
            return state.clinician.patientAccess.filter((v) => !v.deleted);
        },
        getCliniciansDeletedPatientAccess: ({ clinician }) => {
            if (!(clinician && clinician.patientAccess)) return [];
            return clinician.patientAccess.filter((v) => v.deleted);
        },
        getPatientsPatientAccess: (state) => {
            if (!(state && state.patient && state.patient.patientAccess)) return [];
            return state.patient.patientAccess.filter((v) => !v.deleted);
        },
        getPatientAccess: (state, getters) => {
            if (!(state && state.user && state.user.type)) return [];
            if (state.user.type == 'patient')
                return getters.getPatientsPatientAccess;
            else
                return getters.getCliniciansPatientAccess;
            //return state.patient.patientAccess.filter((v) => !v.deleted);
        },
        getPatientAccessRequest: (state) => (id) => {
            if (!(state && state.clinician && state.clinician.patientAccess))
                return null;
            let accessRequests = state.clinician.patientAccess.filter((v) => v.patientAccessId == id);
            if (accessRequests.length == 0)
                return null;
            return accessRequests[0];
        },

        clinicianCanAccessPatientData: ({ clinician }) => (patientId) => {
            if (!(clinician && clinician.patientAccess))
                return false;
            console.log('store getters clinicianCanAccessPatientData - clinician and access-list is present...');
            const currentDate = new Date();
            let canAccess = clinician.patientAccess.filter((v) =>
                v.clinicianId == clinician.id &&
                v.patientId == patientId &&
                v.hasAccess &&
                !v.deleted &&
                !v.paused &&
                (!v.fromDate || new Date(v.fromDate) < currentDate) && 
                (!v.toDate || new Date(v.toDate) > currentDate));
            console.log('store getters clinicianCanAccessPatientData - can access...', canAccess.length, canAccess);
            return canAccess.length > 0;
        },

        //TODO: Rename. This should be something like clinicianIsAssociatedWithPatient...
        clinicianHasAccessToPatient: (state) => (patientId) => {
            if (!(state && state.clinician && state.clinician.patientAccess))
                return false;
            let accessRequests = state.clinician.patientAccess.filter((v) =>
                !v.deleted &&
                v.clinicianId == state.clinician.id &&
                v.patientId == patientId);
            return accessRequests.length > 0;
        },

        getCave: (state) => () => {
            if (!(state && state.patient)) return '';
            return state.patient.cave;
        },

        getPatientRecordEntries: (state) => {
            if (!(state && state.patient && state.patient.record && state.patient.record.entries && Array.isArray(state.patient.record.entries))) return [];
            return state.patient.record.entries;
        },

        getDiagnosis: (state, getters) => (id) => {
            let diags = getters.getPatientDiagnoses.filter((d) => d.diagnosisId == id);
            if (diags.length == 0)
                return null;
            return diags[0];
        },
        getPatientDiagnoses: (state) => {
            if (!(state && state.patient && state.patient.diagnoses && Array.isArray(state.patient.diagnoses))) return [];
            return state.patient.diagnoses;
        },

        getMedication: (state) => (id) => {
            if (!(state && state.patient && state.patient.medications)) return null;
            let meds = state.patient.medications.filter((d) => d.medicationId == id);
            if (meds.length == 0)
                return null;
            return meds[0];
        },
        getPatientMedications: (state) => {
            if (!(state && state.patient && state.patient.medications)) return [];
            return state.patient.medications;
        },

        getImmunization: (state) => (id) => {
            if (!(state && state.patient && state.patient.immunizations)) return null;
            let ims = state.patient.immunizations.filter((i) => i.immunizationId == id);
            if (ims.length == 0)
                return null;
            return ims[0];
        },
        getPatientImmunizations: (state) => {
            if (!(state && state.patient && state.patient.immunizations && Array.isArray(state.patient.immunizations))) return [];
            return state.patient.immunizations;
        },

        getMeasurement: (state, getters) => (id) => {
            let mes = getters.getPatientMeasurements.filter((i) => i.measurementId == id);
            if (mes.length == 0)
                return null;
            return mes[0];
        },

        getMeasurementDefinitions: (state) => {
            if (!(state.measurementDefinitions && Array.isArray(state.measurementDefinitions))) return [];
            return state.measurementDefinitions;
        },
        getMeasurementDefinition: (state, getters) => (code) => {
            let defs = getters.getMeasurementDefinitions.filter((d) => d.valueDefinition.codingCode == code);
            if (defs.length == 0)
                return null;
            return defs[0];
        },

        getConsultationNotes: (state) => {
            if (!(state && state.patient && state.patient.consultationNotes && Array.isArray(state.patient.consultationNotes))) return [];
            return state.patient.consultationNotes;
        },
        getConsultationNote: (state,getters) => (id) => {
            let notes = getters.getConsultationNotes.filter((d) => d.consultationNoteId == id);
            if (notes.length == 0)
                return null;
            return notes[0];
        },

        ///----------------------------------------------------------------------------------------------
        /// Messaging
        ///----------------------------------------------------------------------------------------------
        getClinicsConversations: ({ clinician }) => {
            if (!clinician.conversations) return [];
            return clinician.conversations;
        },
        getPatientsConversations: ({ patient }) => {
            if (!patient.conversations) return [];
            return patient.conversations;
        },
        getConversationMessages: ({ user, patient, clinician }) => (patientId, clinicId) => {
            const fnFilter = (m) => m.patientId == patientId && m.clinicId == clinicId;
            if (user.type == 'patient') {
                if (!patient.messages) return [];
                return patient.messages.filter(fnFilter);
            }
            else {
                if (!clinician.currentConversation) return [];
                return clinician.currentConversation.filter(fnFilter);
            }
        },
        getConversationMessage: ({ user, patient, clinician }) => (messageId) => {
            let msgs;

            if (user.type == 'patient') {
                if (!patient.messages) return null;
                msgs = patient.messages.filter((m) => m.messageId == messageId);
            }
            else {
                if (!clinician.currentConversation) return null;
                msgs = clinician.currentConversation.filter((m) => m.messageId == messageId);
            }

            if (msgs.length == 0) return null;
            return msgs[0];
        },
        getConversationTimestamp: (state) => {
            return state.conversationTimestamp;
        },

        ///----------------------------------------------------------------------------------------------
        /// Episodes of Care
        ///----------------------------------------------------------------------------------------------
        getPatientEpisodesOfCare: ({ patient }) => {
            if (!(patient && patient.episodesOfCare && Array.isArray(patient.episodesOfCare))) return [];
            console.log('getPatientEpisodesOfCare gets something!', patient.episodesOfCare)
            return patient.episodesOfCare;
        },
        getPatientEpisodeOfCare: (state, getters) => (id) => {
            let eoc = getters.getPatientEpisodesOfCare.filter((i) => i.episodeOfCareId == id);
            if (eoc.length == 0)
                return null;
            return eoc[0];
        },

        ///----------------------------------------------------------------------------------------------
        /// Patient consents
        ///----------------------------------------------------------------------------------------------
        getPatientConsents: ({ patient }) => {
            if (!(patient && patient.consents && Array.isArray(patient.consents))) return [];
            return patient.consents;
        },

    },

    mutations: {    // equiv to events - is the only way to change state - mutations are syncronous
        // without payload: funcname (state) { state-mutation }
        // with payload: funcname (state, payload) { state-mutation }
        // invoked by calling store.commit('mutationname')
        // or store.commit('mutationname', payload) to pass payload
        // object can be used to pass payload og call store.commit.
        // e.g store.commit('mutationname', { name: 'some name', value: 4 })
        // or everything in one object
        // e.g. store.commit({ type: 'mutationname', name: 'some name', value: 4 })
        initStore(state) {
            console.log('[store] init store...');

            state.user.id = dispatcher.getFromSession(strings.USERID);
            state.user.userName = dispatcher.getFromLocal(strings.USERNAME);
            state.user.name = dispatcher.getFromLocal(strings.USERSNAME);
            state.user.email = dispatcher.getFromLocal(strings.USERSEMAIL);
            state.user.phone = dispatcher.getFromLocal(strings.USERSPHONE);
            state.user.image = dispatcher.getFromLocal(strings.USERIMAGE);
            state.user.language = dispatcher.getFromLocal(strings.USERLANGUAGE);
            state.user.type = dispatcher.getFromLocal(strings.USERTYPE);
            state.user.notifications = dispatcher.getFromLocal(strings.USERNOTIFICATIONS);
            state.user.pin = dispatcher.getFromLocal(strings.USERPIN);

            state.patient.id = dispatcher.getFromLocal(strings.PATIENTID);
            state.patient.name = dispatcher.getFromLocal(strings.PATIENTNAME);
            state.patient.givenNames = dispatcher.getFromLocal(strings.PATIENTGIVENNAMES);
            state.patient.familyName = dispatcher.getFromLocal(strings.PATIENTFAMILYNAME);
            state.patient.email = dispatcher.getFromLocal(strings.PATIENTEMAIL);
            state.patient.phone = dispatcher.getFromLocal(strings.PATIENTPHONE);
            state.patient.image = dispatcher.getFromLocal(strings.PATIENTIMAGE);
            state.patient.birthdate = dispatcher.getFromLocal(strings.PATIENTBIRTHDATE);
            state.patient.gender = dispatcher.getFromLocal(strings.PATIENTGENDER);
            state.patient.cave = dispatcher.getFromLocal(strings.PATIENTCAVE);
            state.patient.record = dispatcher.getFromLocal(strings.PATIENTRECORD);
            state.patient.diagnoses = dispatcher.getFromLocal(strings.PATIENTDIAGNOSES);
            state.patient.medications = dispatcher.getFromLocal(strings.PATIENTMEDICATIONS);
            state.patient.immunizations = dispatcher.getFromLocal(strings.PATIENTIMMUNIZATIONS);
            state.patient.measurements = dispatcher.getFromLocal(strings.PATIENTMEASUREMENTS);
            state.patient.consultationNotes = dispatcher.getFromLocal(strings.PATIENTCONSULTATIONNOTES);
            state.patient.bookings = dispatcher.getFromLocal(strings.PATIENTBOOKINGS);
            state.patient.requests = dispatcher.getFromLocal(strings.PATIENTREQUESTS);
            state.patient.organizations = dispatcher.getFromLocal(strings.PATIENTORGANIZATIONS);
            state.patient.relatedPersons = dispatcher.getFromLocal(strings.PATIENTRELATEDPERSONS);
            state.patient.invoices = dispatcher.getFromLocal(strings.PATIENTINVOICES);
            state.patient.patientAccess = dispatcher.getFromLocal(strings.PATIENTPATIENTACCESS) || [];
            state.patient.conversations = dispatcher.getFromLocal(strings.PATIENTCONVERSATIONS) || [];
            state.patient.messages = dispatcher.getFromLocal(strings.PATIENTMESSAGES) || [];
            state.patient.episodesOfCare = dispatcher.getFromLocal(strings.PATIENTEPISODESOFCARE) || [];
            state.patient.consents = dispatcher.getFromLocal(strings.PATIENTCONSENTS) || [];

            state.clinician.id = dispatcher.getFromLocal(strings.CLINICIANID);
            state.clinician.clinicId = dispatcher.getFromLocal(strings.CLINICIANCLINICID);
            state.clinician.organizationId = dispatcher.getFromLocal(strings.CLINICIANORGANIZATIONID);
            state.clinician.name = dispatcher.getFromLocal(strings.CLINICIANNAME);
            state.clinician.givenNames = dispatcher.getFromLocal(strings.CLINICIANGIVENNAMES);
            state.clinician.familyName = dispatcher.getFromLocal(strings.CLINICIANFAMILYNAME);
            state.clinician.email = dispatcher.getFromLocal(strings.CLINICIANEMAIL);
            state.clinician.phone = dispatcher.getFromLocal(strings.CLINICIANPHONE);
            state.clinician.image = dispatcher.getFromLocal(strings.CLINICIANIMAGE);
            state.clinician.gender = dispatcher.getFromLocal(strings.CLINICIANGENDER);
            state.clinician.description = dispatcher.getFromLocal(strings.CLINICIANDESCRIPTION);
            state.clinician.bookings = dispatcher.getFromLocal(strings.CLINICIANBOOKINGS);
            state.clinician.requests = dispatcher.getFromLocal(strings.CLINICREQUESTS);
            state.clinician.patientAccess = dispatcher.getFromLocal(strings.CLINICIANPATIENTACCESS) || [];
            state.clinician.conversations = dispatcher.getFromLocal(strings.CLINICIANCONVERSATIONS) || [];
            state.clinician.currentConversation = dispatcher.getFromLocal(strings.CLINICIANCURRENTCONVERSATION) || [];
            state.clinician.locale = dispatcher.getFromLocal(strings.CLINICIANLOCALE) || 'en-US';

            //patientAccess = dispatcher.getFromLocal(strings.PATIENTACCESS) || [],
            state.relatingPatientId = dispatcher.getFromLocal(strings.RELATINGPATIENTID) || null;
            state.isLoading = false;
            state.notificationIsOpen = false;
            state.sideNavigationIsOpen = false;
            state.isOnline = dispatcher.getFromLocalClear(strings.ONLINESTATUS);
            state.conversationTimestamp = new Date().getTime();
            state.measurementDefinitions = dispatcher.getFromLocalClear(strings.MEASUREMENTDEFINITIONS) || [];
            
            console.log('[store] fully initialized...');
        },
        setUserId(state, userId) {
            state.user.id = userId;
            dispatcher.postToSession(strings.USERID, state.user.id);
        },
        setUserInfo(state, userInfo) {
            console.log('[store] set user info + LS');
            state.user.type = userInfo.userType || userInfo.type;
            state.user.userName = userInfo.userName;
            state.user.name = userInfo.name;
            state.user.email = userInfo.email;
            state.user.phone = userInfo.phone;
            state.user.image = userInfo.image;
            state.user.language = userInfo.language;
            state.user.notifications = userInfo.notifications || [];
            dispatcher.postToLocal(strings.USERTYPE, state.user.type);
            dispatcher.postToLocal(strings.USERNAME, state.user.userName);
            dispatcher.postToLocal(strings.USERSNAME, state.user.name);
            dispatcher.postToLocal(strings.USERSEMAIL, state.user.email);
            dispatcher.postToLocal(strings.USERSPHONE, state.user.phone);
            dispatcher.postToLocal(strings.USERIMAGE, state.user.image);
            dispatcher.postToLocal(strings.USERLANGUAGE, state.user.language);
            dispatcher.postToLocal(strings.USERNOTIFICATIONS, state.user.notifications);

            // save users state to persistent/offline storage
            dispatcher.postToLocal(state.user.id, state.user);
            console.log('[store] user info + LS set');
        },
        resetUserInfo(state) {
            if (state.user) {
                state.user.id = null;
                state.user.type = null;
                state.user.userName = null;
                state.user.name = null;
                state.user.image = null;
                state.user.language = null;
                state.user.notifications = null;
            }
            state.relatingPatientId = null;

            dispatcher.deleteFromSession(strings.USERID);
            dispatcher.deleteFromLocal(strings.USERTYPE);
            dispatcher.deleteFromLocal(strings.USERNAME);
            dispatcher.deleteFromLocal(strings.USERSNAME);
            dispatcher.deleteFromLocal(strings.USERSEMAIL);
            dispatcher.deleteFromLocal(strings.USERIMAGE);
            dispatcher.deleteFromLocal(strings.USERLANGUAGE);
            dispatcher.deleteFromLocal(strings.USERNOTIFICATIONS);

            // clean up timestamp for last fetch, so notifications are read on next login
            dispatcher.deleteFromLocal(strings.USERNOTIFICATIONSTIMESTAMP);
            dispatcher.deleteFromLocal(strings.RELATINGPATIENTID);
        },

        setUsername(state, username) {
            state.user.userName = username;
        },
        setUserPIN(state, pin) {
            dispatcher.postToSession(strings.USERPIN, pin);
        },

        setUserLanguage(state, language) {
            state.user.language = language;
            dispatcher.postToLocal(strings.USERLANGUAGE, state.user.language);

            // save users state to persistent/offline storage
            dispatcher.postToLocal(state.user.id, state.user);
        },

        setUserNotifications({ user }, notifications) {
            if (user && user.notifications && user.notifications.length > 0) {
                // merge new notifications into local notifications
                for (var i = 0, l = notifications.length; i < l; i++) {
                    let xn = user.notifications.filter((c) => c.notificationId == notifications[i].notificationId);
                    if (xn.length == 0)
                        user.notifications.push(notifications[i]);
                }
            }
            else {
                // no local notifications, so just overwrite with new ones
                user.notifications = notifications;
            }
            dispatcher.postToLocal(strings.USERNOTIFICATIONS, user.notifications);

            // save users state to persistent/offline storage
            dispatcher.postToLocal(user.id, user);
        },
        addUserNotification({ user }, notification) {
            user.notifications = [...user.notifications||[], notification];
            dispatcher.postToLocal(strings.USERNOTIFICATIONS, user.notifications);

            // save users state to persistent/offline storage
            dispatcher.postToLocal(user.id, user);
        },
        removeUserNotification({ user }, notification) {
            user.notifications = user.notifications.filter((n) => {
                return notification.notificationId != n.notificationId;
            });
            dispatcher.postToLocal(strings.USERNOTIFICATIONS, user.notifications);

            // save users state to persistent/offline storage
            dispatcher.postToLocal(user.id, user);
        },
        clearUserNotifications({ user }) {
            user.notifications = [];
            dispatcher.postToLocal(strings.USERNOTIFICATIONS, user.notifications);

            // save users state to persistent/offline storage
            dispatcher.postToLocal(user.id, user);
        },


        setPatientId(state, patientId) {
            state.patient.id = patientId;
            dispatcher.postToLocal(strings.PATIENTID, state.patient.id);
            //dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        setPatientInfo({ user, patient } , patientInfo) {
            patient.id = patientInfo.id;
            patient.name = [patientInfo.givenNames, patientInfo.familyName].join(' ');
            patient.givenNames = patientInfo.givenNames;
            patient.familyName = patientInfo.familyName;
            patient.email = patientInfo.email;
            patient.phone = patientInfo.phone;
            patient.birthdate = patientInfo.birthdate;
            patient.gender = patientInfo.gender;
            patient.image = patientInfo.image;
            patient.organizations = patientInfo.organizations;
            patient.relatedPersons = patientInfo.relatedPersons;
            //patient.messages = patientInfo.messages;
            //patient.episodesOfCare = patientInfo.episodesOfCare;
            //state.patient.patientAccess = patientInfo.patientAccess;
            patient.pendingEmailChange = patientInfo.pendingEmailChange;
            patient.pendingPhoneChange = patientInfo.pendingPhoneChange;

            console.log('store.setPatientInfo', patientInfo.episodesOfCare);

            dispatcher.postToLocal(strings.PATIENTID, patient.id);
            dispatcher.postToLocal(strings.PATIENTNAME, patient.name);
            dispatcher.postToLocal(strings.PATIENTGIVENNAMES, patient.givenNames);
            dispatcher.postToLocal(strings.PATIENTFAMILYNAME, patient.familyName);
            dispatcher.postToLocal(strings.PATIENTEMAIL, patient.email);
            dispatcher.postToLocal(strings.PATIENTPHONE, patient.phone);
            dispatcher.postToLocal(strings.PATIENTBIRTHDATE, patient.birthdate);
            dispatcher.postToLocal(strings.PATIENTGENDER, patient.gender);
            dispatcher.postToLocal(strings.PATIENTIMAGE, patient.image);
            dispatcher.postToLocal(strings.PATIENTORGANIZATIONS, patient.organizations);
            dispatcher.postToLocal(strings.PATIENTRELATEDPERSONS, patient.relatedPersons);
            //dispatcher.postToLocal(strings.PATIENTCONVERSATIONS, patient.conversations);
            //dispatcher.postToLocal(strings.PATIENTMESSAGES, patient.messages);
            //dispatcher.postToLocal(strings.PATIENTEPISODESOFCARE, patient.episodesOfCare);
            dispatcher.postToLocal(strings.PATIENTEMAILCHANGE, patient.pendingEmailChange);
            dispatcher.postToLocal(strings.PATIENTPHONECHANGE, patient.pendingPhoneChange);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(user.id + '_p_' + patient.id, patient);
        },
        restorePatientInfoStoreAndCache(state, patientInfo) {
            state.patient = patientInfo;
            dispatcher.postToLocal(strings.PATIENTID, state.patient.id);
            dispatcher.postToLocal(strings.PATIENTNAME, state.patient.name);
            dispatcher.postToLocal(strings.PATIENTGIVENNAMES, state.patient.givenNames);
            dispatcher.postToLocal(strings.PATIENTFAMILYNAME, state.patient.familyName);
            dispatcher.postToLocal(strings.PATIENTEMAIL, state.patient.email);
            dispatcher.postToLocal(strings.PATIENTPHONE, state.patient.phone);
            dispatcher.postToLocal(strings.PATIENTBIRTHDATE, state.patient.birthdate);
            dispatcher.postToLocal(strings.PATIENTGENDER, state.patient.gender);
            dispatcher.postToLocal(strings.PATIENTIMAGE, state.patient.image);
            dispatcher.postToLocal(strings.PATIENTORGANIZATIONS, state.patient.organizations);
            dispatcher.postToLocal(strings.PATIENTRELATEDPERSONS, state.patient.relatedPersons);
            dispatcher.postToLocal(strings.PATIENTINVOICES, state.patient.invoices);
            dispatcher.postToLocal(strings.PATIENTPATIENTACCESS, state.patient.patientAccess);
            dispatcher.postToLocal(strings.PATIENTCAVE, state.patient.cave);
            dispatcher.postToLocal(strings.PATIENTRECORD, state.patient.record);
            dispatcher.postToLocal(strings.PATIENTDIAGNOSES, state.patient.diagnoses);
            dispatcher.postToLocal(strings.PATIENTMEDICATIONS, state.patient.medications);
            dispatcher.postToLocal(strings.PATIENTIMMUNIZATIONS, state.patient.immunizations);
            dispatcher.postToLocal(strings.PATIENTMEASUREMENTS, state.patient.measurements);
            dispatcher.postToLocal(strings.PATIENTCONSULTATIONNOTES, state.patient.consultationNotes);
            dispatcher.postToLocal(strings.PATIENTBOOKINGS, state.patient.bookings);
            dispatcher.postToLocal(strings.PATIENTREQUESTS, state.patient.requests);
            dispatcher.postToLocal(strings.PATIENTCONVERSATIONS, state.patient.conversations);
            dispatcher.postToLocal(strings.PATIENTMESSAGES, state.patient.messages);
            dispatcher.postToLocal(strings.PATIENTEPISODESOFCARE, state.patient.episodesOfCare);
            dispatcher.postToLocal(strings.PATIENTCONSENTS, state.patient.consents);
            dispatcher.postToLocal(strings.PATIENTEMAILCHANGE, state.patient.pendingEmailChange);
            dispatcher.postToLocal(strings.PATIENTPHONECHANGE, state.patient.pendingPhoneChange);
        },
        resetPatientInfo(state) {
            if (state.patient) {
                state.patient.id = null;
                state.patient.username = null;
                state.patient.name = null;
                state.patient.givenNames = null;
                state.patient.familyName = null;
                state.patient.birthdate = null;
                state.patient.email = null;
                state.patient.gender = null;
                state.patient.image = null;
                state.patient.phone = null;
                state.patient.cave = null;
                state.patient.record = null;
                state.patient.diagnoses = null;
                state.patient.medications = null;
                state.patient.immunizations = null;
                state.patient.measurements = null;
                state.patient.consultationNotes = null;
                state.patient.bookings = null;
                state.patient.requests = null;
                state.patient.organizations = null;
                state.patient.relatedPersons = null;
                state.patient.invoices = null;
                state.patient.patientAccess = null;
                state.patient.conversations = null;
                state.patient.messages = null;
                state.patient.episodesOfCare = null;
                state.patient.consents = null;
                state.patient.pendingPhoneChange = null;
                state.patient.pendingEmailChange = null;
            }

            dispatcher.deleteFromLocal(strings.PATIENTID);
            dispatcher.deleteFromLocal(strings.PATIENTUSERNAME);
            dispatcher.deleteFromLocal(strings.PATIENTNAME);
            dispatcher.deleteFromLocal(strings.PATIENTGIVENNAMES);
            dispatcher.deleteFromLocal(strings.PATIENTFAMILYNAME);
            dispatcher.deleteFromLocal(strings.PATIENTBIRTHDATE);
            dispatcher.deleteFromLocal(strings.PATIENTEMAIL);
            dispatcher.deleteFromLocal(strings.PATIENTGENDER);
            dispatcher.deleteFromLocal(strings.PATIENTIMAGE);
            dispatcher.deleteFromLocal(strings.PATIENTPHONE);
            dispatcher.deleteFromLocal(strings.PATIENTCAVE);
            dispatcher.deleteFromLocal(strings.PATIENTRECORD);
            dispatcher.deleteFromLocal(strings.PATIENTDIAGNOSES);
            dispatcher.deleteFromLocal(strings.PATIENTMEDICATIONS);
            dispatcher.deleteFromLocal(strings.PATIENTIMMUNIZATIONS);
            dispatcher.deleteFromLocal(strings.PATIENTMEASUREMENTS);
            dispatcher.deleteFromLocal(strings.PATIENTCONSULTATIONNOTES);
            dispatcher.deleteFromLocal(strings.PATIENTBOOKINGS);
            dispatcher.deleteFromLocal(strings.PATIENTREQUESTS);
            dispatcher.deleteFromLocal(strings.PATIENTORGANIZATIONS);
            dispatcher.deleteFromLocal(strings.PATIENTRELATEDPERSONS);
            dispatcher.deleteFromLocal(strings.PATIENTINVOICES);
            dispatcher.deleteFromLocal(strings.PATIENTPATIENTACCESS);
            dispatcher.deleteFromLocal(strings.PATIENTCONVERSATIONS);
            dispatcher.deleteFromLocal(strings.PATIENTMESSAGES);
            dispatcher.deleteFromLocal(strings.PATIENTEPISODESOFCARE);
            dispatcher.deleteFromLocal(strings.PATIENTCONSENTS);
            dispatcher.deleteFromLocal(strings.PATIENTPHONECHANGE);
            dispatcher.deleteFromLocal(strings.PATIENTEMAILCHANGE);
        },

        setRelatedPatientInfo(state) {
            state.relatingPatientId = state.patient.id;
            dispatcher.postToLocal(strings.RELATINGPATIENTID, state.relatingPatientId);

            // save relating patient id state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_' + strings.RELATINGPATIENTID, state.relatingPatientId);
        },
        resetRelatedPatientInfo(state) {
            state.relatingPatientId = null;
            dispatcher.postToLocal(strings.RELATINGPATIENTID, state.relatingPatientId);

            // save relating patient id state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_' + strings.RELATINGPATIENTID, state.relatingPatientId);
        },

        setPatientUsername(state, patientUsername) {
            state.patient.username = patientUsername.patientUsername;
            dispatcher.postToLocal(strings.PATIENTUSERNAME, state.patient.username);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        setPatientName(state, patientInfo) {
            state.patient.givenNames = patientInfo.givenNames;
            state.patient.familyName = patientInfo.familyName;
            state.patient.name = [patientInfo.givenNames, patientInfo.familyName].join(' ');
            dispatcher.postToLocal(strings.PATIENTNAME, state.patient.name);
            dispatcher.postToLocal(strings.PATIENTGIVENNAMES, state.patient.givenNames);
            dispatcher.postToLocal(strings.PATIENTFAMILYNAME, state.patient.familyName);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        setPatientImage(state, patientImage) {
            state.patient.image = patientImage.image;
            dispatcher.postToLocal(strings.PATIENTIMAGE, state.patient.image);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },

        setPatientPendingEmail({ user, patient }, data) {
            patient.pendingEmailChange = data.newEmail;
            dispatcher.postToLocal(strings.PATIENTEMAILCHANGE, patient.pendingEmailChange);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(user.id + '_c_' + patient.id, patient);
        },
        setPendingPatientEmailAsActive({ user, patient }, data) {
            patient.email = patient.pendingEmailChange;
            patient.pendingEmailChange = null;
            dispatcher.postToLocal(strings.PATIENTEMAIL, patient.email);
            dispatcher.postToLocal(strings.PATIENTEMAILCHANGE, patient.pendingEmailChange);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(user.id + '_c_' + patient.id, patient);
        },
        setPatientEmail(state, patientEmail) {
            state.patient.email = patientEmail.email;
            dispatcher.postToLocal(strings.PATIENTEMAIL, state.patient.email);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },

        setPatientPendingPhone({ user, patient }, data) {
            patient.pendingPhoneChange = data.newPhone;
            dispatcher.postToLocal(strings.PATIENTPHONECHANGE, patient.pendingPhoneChange);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(user.id + '_c_' + patient.id, patient);
        },
        setPendingPatientPhoneAsActive({ user, patient }, data) {
            patient.phone = patient.pendingPhoneChange;
            patient.pendingPhoneChange = null;
            dispatcher.postToLocal(strings.PATIENTPHONE, patient.phone);
            dispatcher.postToLocal(strings.PATIENTPHONECHANGE, patient.pendingPhoneChange);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(user.id + '_c_' + patient.id, patient);
        },
        setPatientPhone(state, patientPhone) {
            state.patient.phone = patientPhone.phone;
            dispatcher.postToLocal(strings.PATIENTPHONE, state.patient.phone);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        setPatientBirthdate(state, patientBirthdate) {
            state.patient.birthdate = patientBirthdate.birthdate;
            dispatcher.postToLocal(strings.PATIENTBIRTHDATE, state.patient.birthdate);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        setPatientGender(state, patientGender) {
            state.patient.gender = patientGender.gender;
            dispatcher.postToLocal(strings.PATIENTGENDER, state.patient.gender);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },

        setPatientInvoices(state, patientInvoices) {
            state.patient.invoices = patientInvoices;
            dispatcher.postToLocal(strings.PATIENTINVOICES, state.patient.invoices);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        addPatientInvoice(state, patientInvoice) {
            state.patient.invoices = [...state.patient.invoices||[], patientInvoice];
            dispatcher.postToLocal(strings.PATIENTINVOICES, state.patient.invoices);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },

        addPatientToOrganization(state, organization) {
            state.patient.organizations = [...state.patient.organizations||[], organization];
            dispatcher.postToLocal(strings.PATIENTORGANIZATIONS, state.patient.organizations);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        removePatientFromOrganization(state, organization) {
            state.patient.organizations = state.patient.organizations.filter((orgId) => orgId != organization.organizationId);
            dispatcher.postToLocal(strings.PATIENTORGANIZATIONS, state.patient.organizations);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },

        addPatientRelatedPerson(state, relatedPerson) {
            state.patient.relatedPersons = [...state.patient.relatedPersons||[], relatedPerson];
            dispatcher.postToLocal(strings.PATIENTRELATEDPERSONS, state.patient.relatedPersons);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        removePatientRelatedPerson(state, relatedPerson) {
            state.patient.relatedPersons = state.patient.relatedPersons.filter((p) => p.relatedPersonId != relatedPerson.relatedPersonId);
            dispatcher.postToLocal(strings.PATIENTRELATEDPERSONS, state.patient.relatedPersons);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        changePatientRelatedPersonEmail(state, relatedPerson) {
            state.patient.relatedPersons = state.patient.relatedPersons.map((rp) => {
                if (rp.relatedPersonId == relatedPerson.relatedPersonId) {
                    rp.email = relatedPerson.email;
                }
                return rp;
            });
            dispatcher.postToLocal(strings.PATIENTRELATEDPERSONS, state.patient.relatedPersons);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        changePatientRelatedPersonPhone(state, relatedPerson) {
            state.patient.relatedPersons = state.patient.relatedPersons.map((rp) => {
                if (rp.relatedPersonId == relatedPerson.relatedPersonId) {
                    rp.phone = relatedPerson.phone;
                }
                return rp;
            });
            dispatcher.postToLocal(strings.PATIENTRELATEDPERSONS, state.patient.relatedPersons);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        changePatientRelatedPersonName(state, relatedPerson) {
            state.patient.relatedPersons = state.patient.relatedPersons.map((rp) => {
                if (rp.relatedPersonId == relatedPerson.relatedPersonId) {
                    rp.givenNames = relatedPerson.givenNames;
                    rp.familyName = relatedPerson.familyName;
                }
                return rp;
            });
            dispatcher.postToLocal(strings.PATIENTRELATEDPERSONS, state.patient.relatedPersons);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },

        addHealthInformationToRelatedPerson(state, healthInformation) {
            state.patient.relatedPersons = state.patient.relatedPersons.map((rp) => {
                if (rp.relatedPersonId == healthInformation.relatedPersonId) {
                    rp.relatedPersonPatientId = healthInformation.relatedPersonPatientId;
                    rp.patientDataAccessType = healthInformation.patientDataAccessType;
                }
                return rp;
            });
            dispatcher.postToLocal(strings.PATIENTRELATEDPERSONS, state.patient.relatedPersons);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        removeHealthInformationFromRelatedPerson(state, healthInformation) {
            state.patient.relatedPersons = state.patient.relatedPersons.map((rp) => {
                if (rp.relatedPersonId == healthInformation.relatedPersonId) {
                    rp.relatedPersonPatientId = null;
                    rp.patientDataAccessType = 0;
                }
                return rp;
            });
            dispatcher.postToLocal(strings.PATIENTRELATEDPERSONS, state.patient.relatedPersons);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },

        setClinicianId(state, clinicianId) {
            state.clinician.id = clinicianId;
            dispatcher.postToLocal(strings.CLINICIANID, clinicianId);
            //dispatcher.postToLocal(state.user.id + '_c_' + state.clinician.id, state.clinician);
        },
        setClinicianInfo (state, clinicianInfo) {
            console.log('[store] set clinician info + LS');
            state.clinician.id = clinicianInfo.id;
            state.clinician.clinicId = clinicianInfo.clinicId;
            state.clinician.organizationId = clinicianInfo.organizationId;
            state.clinician.givenNames = clinicianInfo.givenNames;
            state.clinician.familyName = clinicianInfo.familyName;
            state.clinician.name = [clinicianInfo.givenNames, clinicianInfo.familyName].join(' ');
            state.clinician.phone = clinicianInfo.phone;
            state.clinician.email = clinicianInfo.email;
            state.clinician.image = clinicianInfo.image;
            state.clinician.gender = clinicianInfo.gender;
            state.clinician.description = clinicianInfo.description;
            state.clinician.locale = clinicianInfo.locale;
            state.clinician.pendingEmailChange = clinicianInfo.pendingEmailChange;
            state.clinician.pendingPhoneChange = clinicianInfo.pendingPhoneChange;
            dispatcher.postToLocal(strings.CLINICIANID, state.clinician.id);
            dispatcher.postToLocal(strings.CLINICIANCLINICID, state.clinician.clinicId);
            dispatcher.postToLocal(strings.CLINICIANORGANIZATIONID, state.clinician.organizationId);
            dispatcher.postToLocal(strings.CLINICIANNAME, state.clinician.name);
            dispatcher.postToLocal(strings.CLINICIANGIVENNAMES, state.clinician.givenNames);
            dispatcher.postToLocal(strings.CLINICIANFAMILYNAME, state.clinician.familyName);
            dispatcher.postToLocal(strings.CLINICIANPHONE, state.clinician.phone);
            dispatcher.postToLocal(strings.CLINICIANEMAIL, state.clinician.email);
            dispatcher.postToLocal(strings.CLINICIANIMAGE, state.clinician.image);
            dispatcher.postToLocal(strings.CLINICIANGENDER, state.clinician.gender);
            dispatcher.postToLocal(strings.CLINICIANDESCRIPTION, state.clinician.description);
            dispatcher.postToLocal(strings.CLINICIANLOCALE, state.clinician.locale);
            dispatcher.postToLocal(strings.CLINICIANPHONECHANGE, state.clinician.pendingPhoneChange);
            dispatcher.postToLocal(strings.CLINICIANEMAILCHANGE, state.clinician.pendingEmailChange);

            // save clinicians state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_c_' + state.clinician.id, state.clinician);
        },
        restoreClinicianInfoStoreAndCache(state, clinicianInfo) {
            state.clinician = clinicianInfo;
            dispatcher.postToLocal(strings.CLINICIANID, state.clinician.id);
            dispatcher.postToLocal(strings.CLINICIANCLINICID, state.clinician.clinicId);
            dispatcher.postToLocal(strings.CLINICIANORGANIZATIONID, state.clinician.organizationId);
            dispatcher.postToLocal(strings.CLINICIANNAME, state.clinician.name);
            dispatcher.postToLocal(strings.CLINICIANGIVENNAMES, state.clinician.givenNames);
            dispatcher.postToLocal(strings.CLINICIANFAMILYNAME, state.clinician.familyName);
            dispatcher.postToLocal(strings.CLINICIANPHONE, state.clinician.phone);
            dispatcher.postToLocal(strings.CLINICIANEMAIL, state.clinician.email);
            dispatcher.postToLocal(strings.CLINICIANIMAGE, state.clinician.image);
            dispatcher.postToLocal(strings.CLINICIANGENDER, state.clinician.gender);
            dispatcher.postToLocal(strings.CLINICIANDESCRIPTION, state.clinician.description);
            dispatcher.postToLocal(strings.CLINICIANBOOKINGS, state.clinician.bookings);
            dispatcher.postToLocal(strings.CLINICREQUESTS, state.clinician.requests);
            dispatcher.postToLocal(strings.CLINICIANPATIENTACCESS, state.clinician.patientAccess);
            dispatcher.postToLocal(strings.CLINICIANCONVERSATIONS, state.clinician.conversations);
            dispatcher.postToLocal(strings.CLINICIANCURRENTCONVERSATION, state.clinician.currentConversation);
            dispatcher.postToLocal(strings.CLINICIANLOCALE, state.clinician.locale);
            dispatcher.postToLocal(strings.CLINICIANPHONECHANGE, state.clinician.pendingPhoneChange);
            dispatcher.postToLocal(strings.CLINICIANEMAILCHANGE, state.clinician.pendingEmailChange);
        },
        resetClinicianInfo(state) {
            if (state.clinician) {
                state.clinician.id = null;
                state.clinician.clinicId = null;
                state.clinician.organizationId = null;
                state.clinician.name = null;
                state.clinician.givenNames = null;
                state.clinician.familyName = null;
                state.clinician.phone = null;
                state.clinician.email = null;
                state.clinician.gender = null;
                state.clinician.image = null;
                state.clinician.description = null;
                state.clinician.bookings = null;
                state.clinician.requests = null;
                state.clinician.patientAccess = null;
                state.clinician.locale = null;
                state.clinician.pendingEmailChange = null;
                state.clinician.pendingPhoneChange = null;
            }
            dispatcher.deleteFromLocal(strings.CLINICIANID);
            dispatcher.deleteFromLocal(strings.CLINICIANCLINICID);
            dispatcher.deleteFromLocal(strings.CLINICIANORGANIZATIONID);
            dispatcher.deleteFromLocal(strings.CLINICIANNAME);
            dispatcher.deleteFromLocal(strings.CLINICIANGIVENNAMES);
            dispatcher.deleteFromLocal(strings.CLINICIANFAMILYNAME);
            dispatcher.deleteFromLocal(strings.CLINICIANPHONE);
            dispatcher.deleteFromLocal(strings.CLINICIANEMAIL);
            dispatcher.deleteFromLocal(strings.CLINICIANGENDER);
            dispatcher.deleteFromLocal(strings.CLINICIANIMAGE);
            dispatcher.deleteFromLocal(strings.CLINICIANDESCRIPTION);
            dispatcher.deleteFromLocal(strings.CLINICIANBOOKINGS);
            dispatcher.deleteFromLocal(strings.CLINICREQUESTS);
            dispatcher.deleteFromLocal(strings.CLINICIANPATIENTACCESS);
            dispatcher.deleteFromLocal(strings.CLINICIANCONVERSATIONS);
            dispatcher.deleteFromLocal(strings.CLINICIANCURRENTCONVERSATION);
            dispatcher.deleteFromLocal(strings.CLINICIANLOCALE);
            dispatcher.deleteFromLocal(strings.CLINICIANPHONECHANGE);
            dispatcher.deleteFromLocal(strings.CLINICIANEMAILCHANGE);
        },

        setClinicianImage(state, clinicianImage) {
            state.clinician.image = clinicianImage.image;
            dispatcher.postToLocal(strings.CLINICIANIMAGE, state.clinician.image);

            // save clinicians state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_c_' + state.clinician.id, state.clinician);
        },
        setClinicianName(state, clinicianName) {
            state.clinician.givenNames = clinicianName.givenNames;
            state.clinician.familyName = clinicianName.familyName;
            state.clinician.name = [clinicianName.givenNames, clinicianName.familyName].join(' ');
            dispatcher.postToLocal(strings.CLINICIANGIVENNAMES, state.clinician.givenNames);
            dispatcher.postToLocal(strings.CLINICIANFAMILYNAME, state.clinician.familyName);
            dispatcher.postToLocal(strings.CLINICIANNAME, state.clinician.name);

            // save clinicians state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_c_' + state.clinician.id, state.clinician);
        },

        setClinicianPendingEmail({ user, clinician }, data) {
            clinician.pendingEmailChange = data.newEmail;
            dispatcher.postToLocal(strings.CLINICIANEMAILCHANGE, clinician.pendingEmailChange);

            // save clinicians state to persistent/offline storage
            dispatcher.postToLocal(user.id + '_c_' + clinician.id, clinician);
        },
        setPendingClinicianEmailAsActive({ user, clinician }, data) {
            clinician.email = clinician.pendingEmailChange;
            clinician.pendingEmailChange = null;
            dispatcher.postToLocal(strings.CLINICIANEMAIL, clinician.email);
            dispatcher.postToLocal(strings.CLINICIANEMAILCHANGE, clinician.pendingEmailChange);

            // save clinicians state to persistent/offline storage
            dispatcher.postToLocal(user.id + '_c_' + clinician.id, clinician);
        },
        cancelPendingClinicianEmailChange({ user, clinician }, data) {
            clinician.pendingEmailChange = null;
            dispatcher.postToLocal(strings.CLINICIANEMAILCHANGE, clinician.pendingEmailChange);

            // save clinicians state to persistent/offline storage
            dispatcher.postToLocal(user.id + '_c_' + clinician.id, clinician);
        },

        setClinicianPendingPhone({ user, clinician } , data) {
            clinician.pendingPhoneChange = data.newPhone;
            dispatcher.postToLocal(strings.CLINICIANPHONECHANGE, clinician.pendingPhoneChange);

            // save clinicians state to persistent/offline storage
            dispatcher.postToLocal(user.id + '_c_' + clinician.id, clinician);
        },
        setPendingClinicianPhoneAsActive({ user, clinician }, data) {
            clinician.phone = clinician.pendingPhoneChange;
            clinician.pendingPhoneChange = null;
            dispatcher.postToLocal(strings.CLINICIANPHONE, clinician.phone);
            dispatcher.postToLocal(strings.CLINICIANPHONECHANGE, clinician.pendingPhoneChange);

            // save clinicians state to persistent/offline storage
            dispatcher.postToLocal(user.id + '_c_' + clinician.id, clinician);
        },
        cancelPendingClinicianPhoneChange({ user, clinician }, data) {
            clinician.pendingPhoneChange = null;
            dispatcher.postToLocal(strings.CLINICIANPHONECHANGE, clinician.pendingPhoneChange);

            // save clinicians state to persistent/offline storage
            dispatcher.postToLocal(user.id + '_c_' + clinician.id, clinician);
        },

        setClinicianDescription(state, clinicianDescription) {
            state.clinician.description = clinicianDescription.description;
            dispatcher.postToLocal(strings.CLINICIANDESCRIPTION, state.clinician.description);

            // save clinicians state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_c_' + state.clinician.id, state.clinician);
        },
        setClinicianGender(state, clinicianGender) {
            state.clinician.gender = clinicianGender.gender;
            dispatcher.postToLocal(strings.CLINICIANGENDER, state.clinician.gender);

            // save clinicians state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_c_' + state.clinician.id, state.clinician);
        },
        setClinicianBookings(state, bookings) {
            state.clinician.bookings = bookings;
            dispatcher.postToLocal(strings.CLINICIANBOOKINGS, state.clinician.bookings);

            // save clinicians state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_c_' + state.clinician.id, state.clinician);
        },
        addClinicianBooking(state, booking) {
            state.clinician.bookings = [...state.clinician.bookings||[], booking];
            dispatcher.postToLocal(strings.CLINICIANBOOKINGS, state.clinician.bookings);

            // save clinicians state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_c_' + state.clinician.id, state.clinician);
        },
        setClinicBookingRequests(state, requests) {
            state.clinician.requests = requests;
            dispatcher.postToLocal(strings.CLINICREQUESTS, state.clinician.requests);

            // save clinicians state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_c_' + state.clinician.id, state.clinician);
        },


        setPatientsAccess(state, accessRequests) {
            if (state.user.type == 'patient') {
                state.patient.patientAccess = accessRequests;
                dispatcher.postToLocal(strings.PATIENTPATIENTACCESS, state.patient.patientAccess);
                // save patients state to persistent/offline storage
                dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
            }
            else if (state.user.type == 'clinician') {
                state.clinician.patientAccess = accessRequests;
                dispatcher.postToLocal(strings.CLINICIANPATIENTACCESS, state.clinician.patientAccess);
                // save clinicians state to persistent/offline storage
                dispatcher.postToLocal(state.user.id + '_c_' + state.clinician.id, state.clinician);
            }
        },
        setPatientAccessRequest(state, accessRequest) {
            let accessRequests = state.clinician.patientAccess.filter((v) => v.patientAccessId == accessRequest.patientAccessId);
            if (accessRequests.length > 0)
                accessRequests[0].requestDate = new Date().toLocaleDateString();
            else 
                state.clinician.patientAccess = [...state.clinician.patientAccess||[], accessRequest];
            dispatcher.postToLocal(strings.CLINICIANPATIENTACCESS, state.clinician.patientAccess);

            // save clinicians state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_c_' + state.clinician.id, state.clinician);
        },
        setPatientAccessResend(state, resendRequest) {
            state.clinician.patientAccess = state.clinician.patientAccess.map((a) => {
                if (a.patientId == resendRequest.patientId && a.clinicianId == resendRequest.clinicianId && !a.deleted) {
                    a.resendDate = resendRequest.resendDate;
                }
                return a;
            });
            dispatcher.postToLocal(strings.CLINICIANPATIENTACCESS, state.clinician.patientAccess);

            // save clinicians state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_c_' + state.clinician.id, state.clinician);
        },
        setPatientAccessPaused(state, pauseRequest) {
            state.patient.patientAccess = state.patient.patientAccess.map((a) => {
                if (a.patientAccessId == pauseRequest.patientAccessId)
                    a.paused = true;
                return a;
            });
            dispatcher.postToLocal(strings.PATIENTPATIENTACCESS, state.patient.patientAccess);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        setPatientAccessResumed(state, resumeRequest) {
            state.patient.patientAccess = state.patient.patientAccess.map((a) => {
                if (a.patientAccessId == resumeRequest.patientAccessId) {
                    a.paused = false;
                    a.toDate = resumeRequest.endDate;
                }
                return a;
            });
            dispatcher.postToLocal(strings.PATIENTPATIENTACCESS, state.patient.patientAccess);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        setPatientAccessGranted(state, grantRequest) {
            state.patient.patientAccess = state.patient.patientAccess.map((a) => {
                if (a.patientAccessId == grantRequest.patientAccessId) {
                    a.hasAccess = true;
                    a.paused = false;
                    a.toDate = grantRequest.endDate;
                }
                return a;
            });
            dispatcher.postToLocal(strings.PATIENTPATIENTACCESS, state.patient.patientAccess);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        setPatientAccessRevoked(state, denyRequest) {
            state.patient.patientAccess = state.patient.patientAccess.map((a) => {
                if (a.patientAccessId == denyRequest.patientAccessId) {
                    a.hasAccess = false;
                    a.deleted = true;
                }
                return a;
            });
            dispatcher.postToLocal(strings.PATIENTPATIENTACCESS, state.patient.patientAccess);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },


        updatePatientBookings(state, bookingInfo) {
            state.patient.bookings = state.patient.bookings.map((booking) => {
                if (booking.bookingId == bookingInfo.bookingId) {
                    booking.bookingState = 0;
                    booking.rejectionReason = bookingInfo.reason;
                }
                return booking;
            });
            dispatcher.postToLocal(strings.PATIENTBOOKINGS, state.patient.bookings);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        setPatientBookings(state, bookings) {
            state.patient.bookings = bookings;
            dispatcher.postToLocal(strings.PATIENTBOOKINGS, state.patient.bookings);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        setPatientRequests(state, requests) {
            state.patient.requests = requests;
            dispatcher.postToLocal(strings.PATIENTREQUESTS, state.patient.requests);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },


        resetGlobalFlyIns(state) {
            state.notificationIsOpen = false;
            state.sideNavigationIsOpen = false;
        },

        setCave(state, cave) {
            state.patient.cave = cave;
            dispatcher.postToLocal(strings.PATIENTCAVE, state.patient.cave);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },

        setRecord(state, record) {
            state.patient.record = record;
            dispatcher.postToLocal(strings.PATIENTRECORD, state.patient.record);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },

        setDiagnoses(state, diagnoses) {
            state.patient.diagnoses = diagnoses;
            dispatcher.postToLocal(strings.PATIENTDIAGNOSES, state.patient.diagnoses);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        addDiagnosis(state, diagnosis) {
            state.patient.diagnoses = [...state.patient.diagnoses||[], diagnosis];
            dispatcher.postToLocal(strings.PATIENTDIAGNOSES, state.patient.diagnoses);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        changeDiagnosisChronical(state, change) {
            state.patient.diagnoses = state.patient.diagnoses.map((d) => {
                if (change.diagnosisId == d.diagnosisId) {
                    d.chronical = change.chronical;
                    d.date = change.date;
                    d.clinicianId = change.clinicianId;
                    d.clinicianGivenNames = change.clinicianGivenNames;
                    d.clinicianFamilyName = change.clinicianFamilyName;
                }
                return d;
            });
            dispatcher.postToLocal(strings.PATIENTDIAGNOSES, state.patient.diagnoses);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        abateDiagnosis(state, change) {
            state.patient.diagnoses = state.patient.diagnoses.map((d) => {
                if (change.diagnosisId == d.diagnosisId) {
                    d.abated = true;
                    d.chronical = false;
                    d.date = change.date;
                    d.clinicianId = change.clinicianId;
                    d.clinicianGivenNames = change.clinicianGivenNames;
                    d.clinicianFamilyName = change.clinicianFamilyName;
                }
                return d;
            });
            dispatcher.postToLocal(strings.PATIENTDIAGNOSES, state.patient.diagnoses);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        changeDiagnosisAudioNote(state, change) {
            state.patient.diagnoses = state.patient.diagnoses.map((d) => {
                if (d.diagnosisId == change.diagnosisId) {
                    d.audioNote = change.audioNote;
                    d.date = change.date;
                    d.clinicianId = change.clinicianId;
                    d.clinicianGivenNames = change.clinicianGivenNames;
                    d.clinicianFamilyName = change.clinicianFamilyName;
                }
                return d;
            });
            dispatcher.postToLocal(strings.PATIENTDIAGNOSES, state.patient.diagnoses);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },


        setMedications(state, medications) {
            state.patient.medications = medications;
            dispatcher.postToLocal(strings.PATIENTMEDICATIONS, state.patient.medications);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        addMedication(state, medication) {
            state.patient.medications = [...state.patient.medications||[], medication];
            dispatcher.postToLocal(strings.PATIENTMEDICATIONS, state.patient.medications);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        changeMedicationInstruction(state, medicationChange) {
            state.patient.medications = state.patient.medications.map((m) => {
                if (m.medicationId == medicationChange.medicationId) {
                    m.instruction = medicationChange.instruction;
                    m.date = medicationChange.date;
                    m.clinicianId = medicationChange.clinicianId;
                    m.clinicianGivenNames = medicationChange.clinicianGivenNames;
                    m.clinicianFamilyName = medicationChange.clinicianFamilyName;
                }
                return m;
            });
            dispatcher.postToLocal(strings.PATIENTMEDICATIONS, state.patient.medications);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        changeMedicationAudioInstruction(state, medicationChange) {
            state.patient.medications = state.patient.medications.map((m) => {
                if (m.medicationId == medicationChange.medicationId) {
                    m.audioInstruction = medicationChange.audioInstruction;
                    m.date = medicationChange.date;
                    m.clinicianId = medicationChange.clinicianId;
                    m.clinicianGivenNames = medicationChange.clinicianGivenNames;
                    m.clinicianFamilyName = medicationChange.clinicianFamilyName;
                }
                return m;
            });
            dispatcher.postToLocal(strings.PATIENTMEDICATIONS, state.patient.medications);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        deleteMedicationAudioInstruction(state, medicationChange) {
            state.patient.medications = state.patient.medications.map((m) => {
                if (m.medicationId == medicationChange.medicationId) {
                    m.audioInstruction = '';
                    m.date = medicationChange.date;
                    m.clinicianId = medicationChange.clinicianId;
                    m.clinicianGivenNames = medicationChange.clinicianGivenNames;
                    m.clinicianFamilyName = medicationChange.clinicianFamilyName;
                }
                return m;
            });
            dispatcher.postToLocal(strings.PATIENTMEDICATIONS, state.patient.medications);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },


        setImmunizations(state, immunizations) {
            state.patient.immunizations = immunizations;
            dispatcher.postToLocal(strings.PATIENTIMMUNIZATIONS, state.patient.immunizations);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        addImmunization(state, immunization) {
            state.patient.immunizations = [...state.patient.immunizations||[], immunization];
            dispatcher.postToLocal(strings.PATIENTIMMUNIZATIONS, state.patient.immunizations);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        setImmunizationComplete(state, immunization) {
            state.patient.immunizations = state.patient.immunizations.map((i) => {
                if (i.immunizationId == immunization.immunizationId) {
                    i.vaccineComplete = true;
                }
                return i;
            });
            dispatcher.postToLocal(strings.PATIENTIMMUNIZATIONS, state.patient.immunizations);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },

        setMeasurements(state, measurements) {
            state.patient.measurements = measurements;
            dispatcher.postToLocal(strings.PATIENTMEASUREMENTS, state.patient.measurements);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        addMeasurement(state, measurement) {
            state.patient.measurements = [...state.patient.measurements||[], measurement];
            dispatcher.postToLocal(strings.PATIENTMEASUREMENTS, state.patient.measurements);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },

        setMeasurementDefinitions(state, definitions) {
            state.measurementDefinitions = definitions;
            dispatcher.postToLocalClear(strings.MEASUREMENTDEFINITIONS, state.measurementDefinitions);
        },

        setConsultationNotes(state, notes) {
            state.patient.consultationNotes = notes;
            dispatcher.postToLocal(strings.PATIENTCONSULTATIONNOTES, state.patient.consultationNotes);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        addConsultationNote(state, note) {
            state.patient.consultationNotes = [...state.patient.consultationNotes||[], note];
            dispatcher.postToLocal(strings.PATIENTCONSULTATIONNOTES, state.patient.consultationNotes);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },


        setIsLoading(state, load) {
            state.isLoading = load;
        },
        toggleNotifications(state) {
            state.notificationIsOpen = !state.notificationIsOpen;
            state.sideNavigationIsOpen = false;
        },
        toggleSideNavigation(state) {
            state.sideNavigationIsOpen = !state.sideNavigationIsOpen;
            state.notificationIsOpen = false;
        },
        closeSideNavigation(state) {
            state.sideNavigationIsOpen = false;
        },

        setOnlineStatus(state, online) {
            state.isOnline = online;
            dispatcher.postToLocalClear(strings.ONLINESTATUS, state.isOnline);
        },
        reinstateOnlineStatus(state) {
            dispatcher.postToLocalClear(strings.ONLINESTATUS, state.isOnline);
        },


        ///----------------------------------------------------------------------------------------------
        /// Messaging
        ///----------------------------------------------------------------------------------------------
        initPatientConversations(state, conversations) {
            state.patient.conversations = conversations;
            dispatcher.postToLocal(strings.PATIENTCONVERSATIONS, state.patient.conversations);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        initClinicianConversations(state, conversations) {
            console.log('INIT CLINICIAN CONVERSATIONS', conversations);
            state.clinician.conversations = conversations;
            dispatcher.postToLocal(strings.CLINICIANCONVERSATIONS, state.clinician.conversations);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_c_' + state.clinician.id, state.clinician);
        },
        refreshPatientsConversation(state, conversation) {
            if (!conversation || conversation.length == 0) return;
            //TODO: merge new messages in conversation with existing messages for the current patient
            if (!state.patient.messages) state.patient.messages = [];
            conversation.forEach((cm) => {
                if (state.patient.messages.filter((m) => m.messageId == cm.messageId).length == 0)
                    state.patient.messages = [...state.patient.messages||[], cm];
            });
            dispatcher.postToLocal(strings.PATIENTMESSAGES, state.patient.messages);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        refreshCliniciansCurrentConversation(state, conversation) {
            if (!conversation || conversation.length == 0) return;
            state.clinician.currentConversation = conversation;
            dispatcher.postToLocal(strings.CLINICIANCURRENTCONVERSATION, state.clinician.currentConversation);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_c_' + state.clinician.id, state.clinician);
        },
        setConversationTimestamp(state) {
            state.conversationTimestamp = new Date().getTime();
        },
        addPatientMessage(state, message) {
            state.patient.messages = [...state.patient.messages||[], message];
            dispatcher.postToLocal(strings.PATIENTMESSAGES, state.patient.messages);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        addClinicianMessage(state, message) {
            state.clinician.currentConversation = [...state.clinician.currentConversation||[], message];
            dispatcher.postToLocal(strings.CLINICIANCURRENTCONVERSATION, state.clinician.currentConversation);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_c_' + state.clinician.id, state.clinician);
        },


        ///----------------------------------------------------------------------------------------------
        /// Episodes of Care
        ///----------------------------------------------------------------------------------------------
        initEpisodesOfCare(state, episodesOfCare) {
            state.patient.episodesOfCare = episodesOfCare;
            dispatcher.postToLocal(strings.PATIENTEPISODESOFCARE, state.patient.episodesOfCare);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        addEpisodeOfCare(state, episodeOfCare) {
            state.patient.episodesOfCare = [...state.patient.episodesOfCare||[], episodeOfCare];
            dispatcher.postToLocal(strings.PATIENTEPISODESOFCARE, state.patient.episodesOfCare);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        changeEpisodeOfCareEncounterDates(state, episodeOfCare) {
            state.patient.episodesOfCare = state.patient.episodesOfCare.map((i) => {
                if (i.episodeOfCareId == episodeOfCare.episodeOfCareId) {
                    i.firstEncounterDate = episodeOfCare.firstEncounterDate;
                }
                return i;
            });
            dispatcher.postToLocal(strings.PATIENTEPISODESOFCARE, state.patient.episodesOfCare);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        changeEpisodeOfCareTermDate(state, episodeOfCare) {
            console.log('STORE - changeEpisodeOfCareTermDate', episodeOfCare, state.patient.episodesOfCare)
            state.patient.episodesOfCare = [...state.patient.episodesOfCare || []];
            state.patient.episodesOfCare = state.patient.episodesOfCare.map((i) => {
                if (i.episodeOfCareId == episodeOfCare.episodeOfCareId) {
                    console.log('STORE - changeEpisodeOfCareTermDate id', episodeOfCare.episodeOfCareId)
                    i.termDate = episodeOfCare.termDate;
                }
                return i;
            });
            dispatcher.postToLocal(strings.PATIENTEPISODESOFCARE, state.patient.episodesOfCare);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        refreshPregnancyEpisodesOfCare(state, episodesOfCare) {
            console.log('STORE: refresh EOC from', episodesOfCare)
            // update existing episodes of care in the list
            state.patient.episodesOfCare = [...state.patient.episodesOfCare || []];
            state.patient.episodesOfCare = state.patient.episodesOfCare.map((e) => {
                let peoc = episodesOfCare.filter((p) => p.episodeOfCareId == e.episodeOfCareId);
                if (peoc.length) {
                    e.termDate = peoc[0].termDate;
                    e.firstEncounterDate = peoc[0].firstEncounterDate;
                }
                return e;
            });

            console.log('STORE: updated existing EOC', state.patient.episodesOfCare)

            // add new episodes of care to the list
            let newEocs = [];
            episodesOfCare.forEach((p) => {
                let eocs = state.patient.episodesOfCare.filter((e) => e.episodeOfCareId == p.episodeOfCareId);
                if (!eocs.length) {
                    newEocs = [...newEocs, p];
                }
            });
            if(newEocs.length)
                state.patient.episodesOfCare = [...state.patient.episodesOfCare, ...newEocs];

            console.log('STORE: added new EOC', newEocs)

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(strings.PATIENTEPISODESOFCARE, state.patient.episodesOfCare);
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },


        ///----------------------------------------------------------------------------------------------
        /// Patient consents
        ///----------------------------------------------------------------------------------------------
        initPatientConsents(state, consents) {
            state.patient.consents = consents;
            dispatcher.postToLocal(strings.PATIENTCONSENTS, state.patient.consents);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        permitPatientConsents(state, consent) {
            state.patient.consents = [...state.patient.consents || []];
            let existingConsents = state.patient.consents.filter((c) => c.consentType == consent.consentType);
            if (existingConsents.length) {
                let c = existingConsents[0];
                c.consentState = consent.consentState;
                c.recorderId = consent.recorderId;
                c.recorderGivenNames = consent.recorderGivenNames;
                c.recorderFamilyName = consent.recorderFamilyName;
                c.date = consent.date;
            }
            else {
                state.patient.consents = [...state.patient.consents, consent];
            }
            dispatcher.postToLocal(strings.PATIENTCONSENTS, state.patient.consents);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
        denyPatientConsents(state, consent) {
            state.patient.consents = [...state.patient.consents || []];
            let existingConsents = state.patient.consents.filter((c) => c.consentType == consent.consentType);
            if (existingConsents.length) {
                let c = existingConsents[0];
                c.consentState = consent.consentState;
                c.recorderId = consent.recorderId;
                c.recorderGivenNames = consent.recorderGivenNames;
                c.recorderFamilyName = consent.recorderFamilyName;
                c.date = consent.date;
            }
            else {
                state.patient.consents = [...state.patient.consents, consent];
            }
            dispatcher.postToLocal(strings.PATIENTCONSENTS, state.patient.consents);

            // save patients state to persistent/offline storage
            dispatcher.postToLocal(state.user.id + '_p_' + state.patient.id, state.patient);
        },
    },

    actions: {},
    strict: false, //process.env.NODE_ENV !== 'production'
};



export default createStore(storeOptions);