<template>
    <button type="button" class="btn btn__text--green-border" @click="flatten">Flatten</button>
    <button type="button" class="btn btn__text--green-border" @click="deepen">Deepen</button>
    <textarea v-model="obj" style="height:50%;"></textarea>
    <textarea v-model="csv" style="height:50%;"></textarea>
</template>

<script>
    import { ref, onMounted } from 'vue';
    import { useI18n } from 'vue-i18n';

    export default {
        setup() {
            const { messages } = useI18n();

            let csv = ref('');
            let obj = ref({});

            onMounted(() => {
                obj.value = JSON.stringify(messages.value.en);
                csv.value = '';
            });

            const fnFlatten = (obj, lst, prefix) => {
                for (var key in obj) {
                    let dotkey = prefix + (prefix != '' ? '.' : '') + key;
                    if (typeof obj[key] == 'string') {
                        lst.push({ key: dotkey, value: obj[key] });
                    }
                    else {
                        fnFlatten(obj[key], lst, dotkey)
                    }
                }
            }

            const fnDeepenLine = (csvline, deep) => {
                let parts = csvline.replace(/["]/g, '').split(/[;,]/g, 2);
                if (parts.length > 1) {
                    let path = parts[0].split('.');
                    if (path.length > 0) {
                        let pathLen = path.length;
                        let level = 0;
                        let prop = deep;
                        while (level < pathLen) {
                            let newProp = prop[path[level]];
                            if (!newProp) {
                                if (level == pathLen - 1) {
                                    prop[path[level]] = parts[1];
                                    break;
                                }
                                prop[path[level]] = { };
                                newProp = prop[path[level]];
                            }
                            prop = newProp;
                            level++;
                        }
                        //prop = parts[1];
                    }
                }
            }

            const fnDeepen = (csvdoc, deep) => {
                let lines = csvdoc.split('\n');
                lines.forEach((l) => fnDeepenLine(l, deep));
            }

            //const flattened = computed(() => {
            //    console.log(messages.value.en);
            //    let lst = [];
            //    fnFlatten(messages.value.en || {}, lst, '');
            //    console.log(lst);
            //    return lst;
            //});

            //let csv = flatten(() => {
            //    return flattened.value.map(f => `"${f.key}","${f.value}",`).join('\n');
            //});

            const deepen = () => {
                let deep = {};
                fnDeepen(csv.value, deep);
                obj.value = JSON.stringify(deep);
            }

            const flatten = () => {
                let lst = [];
                fnFlatten(JSON.parse(obj.value) || {}, lst, '');
                csv.value = lst.map(f => `"${f.key}","${f.value}",`).join('\n');
            }

            return {
                flatten,
                deepen,
                csv,
                obj,
            }
        }
    }
</script>